<template>
  <div>
    <div class="row">
      <div class="col-md-3">
        <h3 class="mb-3">Lateral Reviews</h3>
      </div>
      <div class="col-md-3">
        <b-form-select
          size="sm"
          :options="projectChoices"
          v-model="projectFilter"
        >
          <template #first>
            <b-form-select-option :value="null"
              >All Projects</b-form-select-option
            >
          </template>
        </b-form-select>
      </div>
      <div class="col-md-3">
        <b-form-select size="sm" v-model="status">
          <template #first>
            <b-form-select-option :value="null"
              >All Statuses</b-form-select-option
            >
          </template>
          <b-form-select-option value="PENDING"
            >Pending Review</b-form-select-option
          >
          <b-form-select-option value="APPROVED">Approved</b-form-select-option>
          <b-form-select-option value="ADDITIONAL_WORK"
            >Needs Additional Work</b-form-select-option
          >
          <b-form-select-option value="REJECTED">Rejected</b-form-select-option>
          <b-form-select-option value="PASSED"
            >Passed Under Condition</b-form-select-option
          >
        </b-form-select>
      </div>
      <div class="col-md-3">
        <b-form-group
          label-align-sm="right"
          label-size="sm"
          label-for="filterInput"
          class="mb-0"
        >
          <b-input-group size="sm">
            <b-form-input
              v-model="filter"
              type="search"
              id="filterInput"
              placeholder="Type to Search"
            ></b-form-input>
            <b-input-group-append>
              <b-button :disabled="!filter" @click="filter = ''"
                >Clear</b-button
              >
            </b-input-group-append>
            <b-button
              size="sm"
              class="ml-1"
              @click="refreshTable"
              title="Refresh table"
              ><font-awesome-icon icon="sync"
            /></b-button>
            <b-button
              v-if="districtInfo && districtInfo[0].allow_excel_export"
              size="sm"
              class="ml-1"
              :href="spreadsheetlink"
              title="Export table as Excel"
              ><font-awesome-icon icon="file-excel"
            /></b-button>
            <b-button
              v-else
              size="sm"
              class="ml-1"
              title="Export table as Excel"
              @click="showExcelRejectionMessage"
              ><font-awesome-icon icon="file-excel"
            /></b-button>
          </b-input-group>
        </b-form-group>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <b-table
          striped
          :items="getInspections"
          :fields="fields"
          primary-key="id"
          :sort-by="sortBy"
          :sort-desc="sortDesc"
          :filter="filter"
          :per-page="perPage"
          :current-page="currentPage"
          table-class="iefix"
          show-empty
          small
          outlined
          responsive
          ref="table"
        >
          <template v-slot:table-busy>
            <b-spinner class="align-middle"></b-spinner>
          </template>
          <template v-slot:empty>
            <div class="text-center">
              <h4 class="d-inline">No inspections yet.</h4>
              <p>
                Inspections submitted to your municipality will appear here once
                they are saved.
              </p>
            </div>
          </template>
          <template v-slot:cell(site_info.address)="data">
            {{ data.item.site_info.address.split(",").splice(0, 2).join(",") }}
            <br />
            <b-link
              :to="{
                name: 'inspection-detail',
                params: { inspectionId: data.item.id },
              }"
              >ID# {{ data.item.id }}</b-link
            >
          </template>
          <template v-slot:cell(inspector_info.company_name)="data">
            {{ data.item.inspector_info.company.name }}
          </template>
          <template v-slot:cell(submitted)="data">
            <span v-if="data.item.status === 'SUBMITTED'">{{
              data.item.submitted | dateTimeFormat
            }}</span>
          </template>
          <template v-slot:cell(cityreview.status)="data">
            <span v-if="data.item.status === 'SUBMITTED'">
              <span v-if="data.item.cityreview && !data.item.cityreview.draft">
                <span
                  v-if="data.item.cityreview.status == 'APPROVED'"
                  class="text-success"
                >
                  Approved
                  <span v-if="data.item.cityreview.approved_date">{{
                    data.item.cityreview.approved_date | dateFormat
                  }}</span>
                </span>
                <span
                  v-if="data.item.cityreview.status == 'REJECTED'"
                  class="text-danger"
                  >Rejected</span
                >
                <span
                  v-if="data.item.cityreview.status == 'ADDITIONAL_WORK'"
                  class="text-warning"
                  >Needs additional work</span
                >
                <span
                  v-if="data.item.cityreview.status == 'PASSED'"
                  class="text-warning"
                  >Passed under condition</span
                >
              </span>
              <span v-else> Pending Review </span>
            </span>
          </template>
          <template v-slot:cell(cityreview.reviewer_info.last_name)="data">
            <span v-if="data.item.cityreview != null">
              {{ data.item.cityreview.reviewer_info.first_name }}
              {{ data.item.cityreview.reviewer_info.last_name }}
            </span>
          </template>
          <template v-slot:cell(notes)="data">
            <div
              class="text-center"
              v-if="data.item.cityreview && data.item.cityreview.notes"
              v-b-tooltip.hover
              :title="data.item.cityreview.notes"
            >
              <font-awesome-icon icon="sticky-note" />
            </div>
          </template>
          <template v-slot:cell(actions)="data">
            <b-button
              variant="outline-primary"
              :to="{
                name: 'inspection-detail',
                params: { inspectionId: data.item.id },
              }"
              size="sm"
              :disabled="data.item.status != 'SUBMITTED'"
            >
              <span v-if="data.item.cityreview === null"> Review </span>
              <span v-else> Update </span>
            </b-button>
          </template>
        </b-table>
        <div class="overflow-auto">
          <b-pagination
            v-model="currentPage"
            :total-rows="total"
            :per-page="perPage"
            aria-controls="my-table"
            align="right"
          >
          </b-pagination>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { HTTP } from "../../http.js";
import moment from "moment";
export default {
  name: "InspectionTableDistrict",
  props: ["inspections", "loaded"],
  data: function () {
    return {
      filter: "",
      sortBy: "submitted",
      sortDesc: true,
      currentPage: 1,
      perPage: 20,
      total: 0,
      onlyPending: false,
      showInProgress: false,
      status: null,
      districtInfo: null,
      projectFilter: null,
      fields: [
        {
          key: "site_info.address",
          sortable: true,
          label: "Address",
          formatter: "address",
        },
        { key: "site_info.project_name", sortable: true, label: "Project" },
        {
          key: "initial_inspection",
          sortable: true,
          label: "Re-inspected",
          formatter: "negBoolFmt",
        },
        { key: "submitted", sortable: true, label: "Submittal" },
        {
          key: "inspector_info.company_name",
          sortable: true,
          label: "Inspector",
        },
        {
          key: "cityreview.status",
          sortable: true,
          label: "Municipality Review",
        },
        {
          key: "cityreview.reviewer_info.last_name",
          sortable: true,
          label: "Municipality Reviewer",
        },
        { key: "notes", label: "Notes" },
        { key: "actions", label: "" },
      ],
    };
  },
  mounted() {
    HTTP.get("/api/districts/?all=true").then((response) => {
      this.districtInfo = response.data.filter(
        (district) => this.user.admin_districts.indexOf(district.id) >= 0
      );
    });
  },
  methods: {
    keyToOrder(key) {
      switch (key) {
        case "site_info.address":
          return "site__address";
        case "reinspection":
          return "reinspection";
        case "status":
          return "status";
        case "submitted":
          return "submitted";
        case "inspector_info.company_name":
          return "inspector__profile__company__name";
        case "cityreview.status":
          return "cityreview__status";
        case "cityreview.reviewer_info.last_name":
          return "cityreview__reviewer__last_name";
        case "site_info.owner_last_name":
          return "site__owner_last_name";
        case "site_info.project":
          return "site__project__name";
        default:
          return "submitted";
      }
    },
    getInspections(ctx) {
      const orderDir = ctx.sortDesc ? "-" : "";
      const orderKey = this.keyToOrder(ctx.sortBy);
      let querystring =
        "/api/inspections/?page=" +
        ctx.currentPage +
        "&search=" +
        ctx.filter +
        "&order=" +
        orderDir +
        orderKey;
      if (this.onlyPending) {
        querystring = querystring + "&pending=True";
      }
      if (!this.showInProgress) {
        querystring = querystring + "&in_progress=False";
      }
      if (this.status != null) {
        querystring = querystring + "&review_status=" + this.status;
      }
      if (this.projectFilter != null) {
        querystring = querystring + "&site__project=" + this.projectFilter;
      }
      const promise = HTTP.get(querystring);
      return promise.then((data) => {
        const items = data.data.results;
        this.total = data.data.count;
        return items || [];
      });
    },
    address: function (value) {
      let parts = value.split(",");
      const addresscity = parts.splice(0, 2);
      return addresscity.join(",");
    },
    boolfmt: function (value) {
      return value ? "Yes" : "No";
    },
    negBoolFmt: function (value) {
      return value ? "No" : "Yes";
    },
    refreshTable: function () {
      this.$refs.table.refresh();
    },
    showExcelRejectionMessage() {
      alert("You must purchase an export plan to enable this feature.");
    },
  },
  watch: {
    onlyPending: function () {
      this.refreshTable();
    },
    showInProgress: function () {
      this.refreshTable();
    },
    status: function () {
      this.refreshTable();
    },
    projectFilter() {
      this.refreshTable();
    },
  },
  computed: {
    spreadsheetlink() {
      return process.env.VUE_APP_API_ROOT + "/api/spreadsheet/";
    },
    user() {
      return this.$store.getters.user;
    },
    projects() {
      if (this.districtInfo) {
        return this.districtInfo
          .map((d) => {
            return d.project_set;
          })
          .flat();
      } else {
        return [];
      }
    },
    projectChoices() {
      return this.projects.map((p) => {
        return { text: p.name, value: p.id };
      });
    },
  },
  filters: {
    dateTimeFormat: function (value) {
      return moment(value).format("M/D/YYYY h:mm a");
    },
    dateFormat(value) {
      return moment(value).format("M/D/YY");
    },
  },
};
</script>
<style>
_:-ms-fullscreen,
:root .iefix {
  table-layout: fixed;
}
</style>
