<template>
  <b-form-group
    :id="'input-group-' + eleId"
    :label="question.label"
    :label-for="'input-' + eleId"
  >
    <b-form-input
      :id="'input-' + eleId"
      type="number"
      v-model="value"
      :required="question.required"
      @input="doDebounce"
    ></b-form-input>
  </b-form-group>
</template>
<script>
import debounce from 'lodash/debounce'
export default {
  name: 'FbNumber',
  props: {
    question: Object,
    initial: {
      type: [Number, String],
      default: null
    }
  },
  data() {
    return {
      value: this.initial === null ? this.question.value : this.initial
    }
  },
  computed: {
    eleId() {
      return `question-${this.question.id}`
    }
  },
  created(){
    this.doDebounce = debounce(this.sendAnswer, 500)
  },
  methods:{
    sendAnswer(){
      this.$emit('answered', {answer: this.value, question: this.question.id})
    }
  },
  mounted(){
    this.sendAnswer()
  }
}
</script>
