<template>
  <div class="builderpane">
    <div class="dropzone"
      @drop="onDrop($event, null)"
      @dragover.prevent
      @dragenter.prevent="dragEntered($event)"
      @dragexit.prevent="dragExited($event)"
      @dragleave.prevent="dragExited($event)"
      v-if="selectedQuestionSet"
    >
      <div
        class="droppable"
        v-for="(question, index) in questionList"
        :key="question.id"
        @drop="onDrop($event, index)"
        @dragover.prevent
        @dragenter.prevent="dragEntered($event)"
        @dragexit.prevent="dragExited($event)"
        @dragleave.prevent="dragExited($event)"
        draggable
        @dragstart="startDrag($event, question)"
      >
        <span class="delete-button border border-danger shadow-sm rounded-circle">
          <b-link class="text-dark" @click="removeQuestionFromSet(question.id)"><font-awesome-icon icon="times" fixed-width></font-awesome-icon></b-link>
        </span>
        <question-mapper :question="question"></question-mapper>
      </div>
    </div>
    <div v-else>
      <p>Please select a question set on the right</p>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import QuestionMapper from './QuestionMapper.vue'

export default {
  components: { QuestionMapper },
  name: 'BuilderPane',
  data() {
    return {}
  },
  methods: {
    removeQuestionFromSet(questionId){
      const updatedQuestionPlacements = this.selectedQuestionSet.questionplacement_set.filter(qp => qp.question != questionId).map((qp, index) => {
        return {...qp, order: index}
      })
      const updatedQuestionSet = {...this.selectedQuestionSet, questionplacement_set: updatedQuestionPlacements}
      this.$store.dispatch('updateQuestionSet', updatedQuestionSet)
    },
    onDrop(event, targetIndex) {
      if (targetIndex == null && this.questionList.length > 0) {
        return
      }
      if (targetIndex == null && this.questionList.length < 1) {
        targetIndex = 0
      }

      event.target.classList.remove('dragover')
      const droppedQuestionId = parseInt(event.dataTransfer.getData('question'))

      const newQuestionPlacement = {question: droppedQuestionId, order: targetIndex}
      // First remove the question in the questionplacement set if it already exists.
      let updatedQuestionPlacements = this.selectedQuestionSet.questionplacement_set.filter(qp => qp.question != droppedQuestionId).map((qp, index) => {
        // increment the order of any placement that has a higher index than the one that dropped.
        if(qp.order >= targetIndex){
          return {...qp, order: index + 1}
        }else{
          return {...qp, order: index}
        }
      })
      // Finally add the new questionplacement
      updatedQuestionPlacements.splice(targetIndex, 0, newQuestionPlacement)

      const updatedQuestionSet = {...this.selectedQuestionSet, questionplacement_set: updatedQuestionPlacements}
      this.$store.dispatch('updateQuestionSet', updatedQuestionSet)
    },
    dragEntered(event){
      if(event.target.classList && (event.target.classList.contains('d-block') || event.target.classList.contains('dropzone'))){
        event.target.classList.add('dragover')
      }
      return
    },
    dragExited(event){
      if(event.target.classList){
        event.target.classList.remove('dragover')
      }
      return
    },
    startDrag(event, question) {
      event.dataTransfer.dropEffect = 'move'
      event.dataTransfer.effectAllowed = 'move'
      event.dataTransfer.setData('question', question.id)
    }
  },
  computed: {
    ...mapState({
      questions: state =>  state.formbuilder.questions,
      selectedQuestionSet: state => state.formbuilder.selectedQuestionSet,
    }),
    questionList() {
      if (!this.selectedQuestionSet){
        return []
      }
      return this.selectedQuestionSet.questionplacement_set.map(qp => {
        return this.questions.find(q => {
          return qp.question === q.id
        })
      })
    }
  }
}
</script>
<style>
.dropzone {
  background-color: #eee;
  margin-bottom: 10px;
  padding: 10px;
}
.dragover {
  border-top: 3px solid orangered;
}
.delete-button {
  background: red;
  position: absolute;
  right: 5%;
  z-index: 100;
}
</style>
