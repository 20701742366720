<template>
  <b-button @click="pickFile">Choose File</b-button>
</template>
<script>
  import Robodog from '@uppy/robodog'
  import {utcDateString} from '../../utils'
  import {HTTP} from '../../http'
  export default {
    name: 'RoboPickerUpload',
    props: {
      template: {
        type: String,
      },
      fields: {
        type: Object,
        default: function(){
          return {}
        }
      },
      multi: {
        type: Boolean,
        default: function(){
          return false
        }
      }
    },
    data: function(){
      const expiresIn  = 1000 * 60 * 60 * 24
      const expires = utcDateString((+new Date()) + expiresIn)
      return {
        signature: '',
        params:{
          auth: {
            key: process.env.VUE_APP_TRANSLOADIT_PUBLIC_KEY,
            expires: expires
          },
          template_id: this.template
        }
      }
    },
    mounted: function(){
      HTTP.post('/api/signupload/', this.params).then(response => {
        this.signature = response.data.signature
      })
    },
    methods: {
      pickFile: function(){
        Robodog.pick({
          params: this.params,
          signature: this.signature,
          fields: this.fields,
          waitForEncoding: true,
          autoProceed: this.multi ? false : true,
          restrictions:{
            maxNumberOfFiles: this.multi ? 0 : 1,
            minNumberOfFiles: 1,
          },
          providers: ['dropbox', 'google-drive']
        }).then(result => {
          if(result.results.length < 1){
            this.$store.commit('setMessage', {
              message: 'There was a problem with that upload, please try again',
              variant: 'danger'
            })
          }else{
            this.$emit('upload-success', {ssl_url: result.results[0].ssl_url, uploadURL: result.successful[0].uploadURL})
          }
        })
      }
    }
  }
</script>
