<template>
  <div class="container-fluid">
    <b-form @submit.prevent="onSubmit">
      <div class="form-row">
        <b-form-group
          id="input-group-name"
          class="col-sm"
          label="Question Name"
          label-for="input-name"
        >
          <b-form-input
            id="input-name"
            v-model="form.name"
            type="text"
            required
          ></b-form-input>
        </b-form-group>

        <b-form-group
          id="input-group-label"
          class="col-sm"
          label="Question Label"
          label-for="input-label"
        >
          <b-form-input
            id="input-label"
            v-model="form.label"
            type="text"
            required
          ></b-form-input>
        </b-form-group>

        <b-form-group
          id="input-group-value"
          class="col-sm"
          label="Default Answer"
          label-for="input-value"
        >
          <b-form-input
            id="input-value"
            v-model="form.value"
            type="text"
          ></b-form-input>
        </b-form-group>

        <b-form-group
          id="input-group-required"
          class="col-sm"
          label="Required"
        >
          <b-form-radio name="required" v-model="form.required" :value="true">True</b-form-radio>
          <b-form-radio name="required" v-model="form.required" :value="false">False</b-form-radio>
        </b-form-group>

      </div>

      <div class="form-row">
        <b-form-group
          id="input-control-type"
          class="col-sm"
          label="Control Type"
          label-for="input-control-type"
        >
          <b-form-select
              id="input-control-type"
              v-model="form.control_type"
              :disabled="readonlyFields"
              required
            >
              <option value="INPUT" selected>INPUT</option>
              <option value="RADIO">RADIO</option>
              <option value="TEXTAREA">TEXTAREA</option>
              <option value="NUMBER">NUMBER</option>
              <option value="DROPDOWN">DROPDOWN</option>
              <option value="DATE">DATE</option>
              <option value="TIME">TIME</option>
              <option value="DATETIME">DATETIME</option>
              <option value="CHECKBOX">CHECKBOX</option>
            </b-form-select>
        </b-form-group>

        <b-form-group
          id="input-value-type"
          class="col-sm"
          label="Value Type"
          label-for="input-value-type"
        >
          <b-form-select
              id="input-value-type"
              v-model="form.value_type"
              :disabled="readonlyFields"
              required
            >
              <option value="STRING" selected>STRING</option>
              <option value="NUMBER">NUMBER</option>
              <option value="BOOLEAN">BOOLEAN</option>
            </b-form-select>
        </b-form-group>
        <b-form-group
          id="input-control-width"
          class="col-sm"
          label="Control Width"
          label-for="input-control-width"
        >
          <b-form-select
              id="input-control-width"
              v-model="form.width"
              required
            >
              <option :value="1" selected>1</option>
              <option :value="2" selected>2</option>
              <option :value="3" selected>3</option>
              <option :value="4" selected>4</option>
              <option :value="5" selected>5</option>
              <option :value="6" selected>6</option>
              <option :value="7" selected>7</option>
              <option :value="8" selected>8</option>
              <option :value="9" selected>9</option>
              <option :value="10" selected>10</option>
              <option :value="11" selected>11</option>
              <option :value="12" selected>12</option>
            </b-form-select>
        </b-form-group>
      </div>

      <div class="form-row">
        <b-form-group
          id="input-group-choices"
          class="col-sm"
          label="Choices"
          label-for="input-value"
        >
          <b-form-input
            id="input-choices"
            v-model="modelChoices"
            type="text"
          ></b-form-input>
        </b-form-group>
      </div>
      <div class="form-row">
        <b-button variant="success" type="submit">Save</b-button>
      </div>
    </b-form>
  </div>
</template>
<script>
export default {
  name: 'QuestionForm',
  props: {
    initial : {
      type: Object,
      default() {
        return {
          name: '',
          label: '',
          value: '',
          required: false,
          control_type: 'INPUT',
          value_type: 'STRING',
          width: 12,
          choices: []
        }
      }
    },
    readonlyFields: {
      type: Boolean,
      default: false
    }
  },
  data() {
    let modelChoices = ''
    if (this.initial.choices){
      modelChoices = this.initial.choices.map(c => c.value).join(', ')
    }
    return {
      form: { ...this.initial },
      modelChoices: modelChoices,
    }
  },
  computed: {
    fullChoices() {
      if(this.modelChoices) {
        return this.modelChoices.split(',').map(c => { return {name: c, value: c}})
      } else {
        return []
      }
    }
  },
  methods: {
    onSubmit() {
      if(this.fullChoices){
        this.form.choices = this.fullChoices
      }
      this.$emit('form-submitted', this.form)
    }
  },
  watch: {
    initial() {
      this.form = {...this.initial}
      this.modelChoices = this.initial.choices.map(c => c.value).join(', ')
    }
  }
}
</script>
