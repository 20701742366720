<template>
  <div>
    <div v-if="!company">
      <Spinner></Spinner>
    </div>
    <div v-else>
      <b-alert v-model="hasError" variant="danger">
        <p>
          There were errors with your submission. Please correct them below:
        </p>
        <ul>
          <li v-for="(error, field) in errors" v-bind:key="field">
            {{ field }}: <span v-for="e in error" v-bind:key="e">{{ e }}</span>
          </li>
        </ul>
      </b-alert>
      <br />
      <div class="form-row">
        <b-form-group
          id="input-group-code"
          class="col-sm"
          label="Company code. Share this with coworkers if they want to sign up for Forward Lateral."
          label-for="input-code"
        >
          <b-form-input
            id="input-code"
            v-model="company.code"
            readonly
          ></b-form-input>
        </b-form-group>
      </div>
      <b-form @submit.prevent="onSubmit">
        <div class="form-row">
          <b-form-group
            id="input-group-name"
            class="col-sm"
            label="Business Name"
            label-for="input-name"
          >
            <b-form-input
              id="input-name"
              v-model="company.name"
              required
            ></b-form-input>
          </b-form-group>
          <b-form-group
            id="input-group-address"
            class="col-sm"
            label="Business address"
            label-for="input-address"
          >
            <b-form-input
              id="input-address"
              v-model="company.address"
              required
            ></b-form-input>
          </b-form-group>
        </div>
        <div class="form-row">
          <b-form-group
            id="input-group-phone"
            class="col-sm"
            label="Business phone"
            label-for="input-phone"
          >
            <b-form-input
              id="input-phone"
              v-model="company.phone"
              v-mask="'(###) ###-####'"
              required
            ></b-form-input>
          </b-form-group>
          <b-form-group
            id="input-group-license_number"
            class="col-sm"
            label="License Number"
            label-for="input-license-number"
          >
            <b-form-input
              id="input-license-number"
              v-model="company.license_number"
            ></b-form-input>
          </b-form-group>
          <b-form-group
            id="id-website"
            class="col-sm"
            label="Website"
            label-for="input-website"
          >
            <b-form-input
              id="input-website"
              v-model="company.website"
            ></b-form-input>
          </b-form-group>
        </div>
        <div class="form-row">
          <b-form-group
            id="input-group-districts"
            class="col-sm"
            label="Available Municipalities"
            label-for="input-districts"
          >
            <b-form-select
              id="input-districts"
              v-model="company.available_districts"
              :options="districts"
              multiple
              :select-size="4"
            ></b-form-select>
          </b-form-group>
          <b-form-group
            id="input-group-logo"
            class="col-sm"
            label="Logo"
            label-for="input-logo"
            description="Plase use the save button after adding or removing a logo."
          >
            <b-input-group>
              <b-form-file
                v-model="newlogo"
                placeholder="Choose a file or drop it here..."
                drop-placeholder="Drop file here..."
              ></b-form-file>
              <b-input-group-append>
                <b-button
                  variant="danger"
                  @click="resetLogo"
                  :disabled="!company.logo"
                  >Remove</b-button
                >
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
          <div class="current-logo col-3">
            <img v-if="company.logo" :src="company.logo" />
          </div>
        </div>
        <b-button type="submit" variant="primary" :disabled="loading">
          <Spinner :small="true" variant="dark" v-show="loading"></Spinner>Save
        </b-button>
      </b-form>
    </div>
  </div>
</template>
<script>
import { HTTP } from "@/http";
import Spinner from "@/components/util/Spinner";
export default {
  name: "CompanyForm",
  components: { Spinner },
  data: function () {
    return {
      hasError: false,
      loading: false,
      errors: [],
      company: null,
      districts: [],
      newlogo: null,
      clearLogo: false,
    };
  },
  mounted() {
    this.fetchCompany();
    this.fetchDistricts();
  },
  methods: {
    fetchCompany() {
      HTTP.get("/api/accounts/company/")
        .then((response) => {
          this.company = response.data;
        })
        .catch((e) => {
          this.$store.commit("setMessage", { message: e, variant: "error" });
        });
    },
    fetchDistricts() {
      HTTP.get("/api/districts/?all=true")
        .then((response) => {
          this.districts = response.data.map((district) => {
            return { value: district.id, text: district.name };
          });
        })
        .catch((e) => {
          this.$store.commit("setMessage", { message: e, variant: "error" });
          this.errors = e;
        });
    },
    onSubmit() {
      this.loading = true;
      let formData = new FormData();
      if (this.newlogo) {
        formData.append("logo", this.newlogo);
      } else if (this.clearLogo) {
        formData.append("logo", new File([], ""));
      }
      for (var i = 0; i < this.company.available_districts.length; i++) {
        formData.append(
          `available_districts`,
          this.company.available_districts[i],
        );
      }
      formData.append("name", this.company.name);
      formData.append("address", this.company.address);
      formData.append("phone", this.company.phone);
      formData.append("license_number", this.company.license_number);
      formData.append("website", this.company.website);
      HTTP.put("/api/accounts/company/", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then((response) => {
          this.company = response.data;
          this.$store.dispatch("fetchUserData");
          this.$store.commit("setMessage", {
            message: "Company Saved",
            variant: "info",
          });
        })
        .catch((e) => {
          this.$store.commit("setMessage", { message: e, variant: "error" });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    resetLogo() {
      this.clearLogo = true;
      this.newlogo = null;
      this.company.logo = null;
    },
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
  },
};
</script>
<style>
.current-logo > img {
  max-height: 200px;
}
</style>
