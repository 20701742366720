<template>
  <div class="row">
    <div class="col">
      <p v-show="form.modified">
        <em>Last Update: {{ form.modified | formatDateTime }}</em>
      </p>
      <b-form>
        <b-form-group>
          <b-form-radio v-model="form.status" value="APPROVED" size="lg">
            Lateral passes inspection.
          </b-form-radio>
          <b-form-radio v-model="form.status" value="ADDITIONAL_WORK" size="lg">
            Lateral needs additional work.
          </b-form-radio>
          <b-form-radio v-model="form.status" value="REJECTED" size="lg">
            The inspection has a technical problem, or the form was filled out
            incorrectly.
          </b-form-radio>
          <b-form-radio v-model="form.status" value="PASSED" size="lg">
            An additional step is required for final approval, but the
            municipality has passed your first inspection.
          </b-form-radio>
        </b-form-group>
        <p>Letter to contact:</p>
        <p v-if="documentUrl">
          <b-link :href="documentUrl">{{ documentFilename }}</b-link>
        </p>
        <RoboPickerUpload
          :template="transTemplate"
          @upload-success="fileuploaded"
          :multi="true"
        ></RoboPickerUpload>
        <p class="form-text text-muted">
          <small>
            Uploading multiple files? All files must be selected together and
            uploaded only once. This may require moving all files to one folder
            and selecting them using the Command key on MacOS or Control on
            Windows. Any file type supported, including .pdf, .zip, etc.
          </small>
        </p>
        <b-form-group
          id="input-group-pub-notes"
          label="Public Notes"
          description="Visible to the inspectors and property contacts."
          label-for="input-pub-notes"
        >
          <b-form-textarea
            id="input-pub-notes"
            v-model="form.pub_notes"
            rows="5"
          ></b-form-textarea>
        </b-form-group>
        <b-form-group
          id="input-group-notes"
          label="Private Notes"
          description="Visibile to municipality employees only."
          label-for="input-notes"
        >
          <b-form-textarea
            id="input-notes"
            v-model="form.notes"
            rows="5"
          ></b-form-textarea>
        </b-form-group>
        <b-checkbox
          v-if="moratorium"
          class="mb-1"
          v-model="moratorium_approved"
        >
          Remember to check if there’s preservation ordinance that may call for
          a deferral or trenchless solution. If applicable, specify it in Public
          Notes.
        </b-checkbox>
        <span
          class="d-inline-block"
          v-b-tooltip.hover
          :title="disableSave ? 'Please check the checkbox above.' : ''"
        >
          <b-button
            variant="primary"
            @click.prevent="onSubmit(true)"
            :disabled="disableSave"
            >Save</b-button
          >&nbsp;
          <b-button
            variant="success"
            @click.prevent="onSubmit(false)"
            :disabled="disableSave"
            >Save and Submit to Property Contact and Inspector</b-button
          >
        </span>
      </b-form>
    </div>
  </div>
</template>
<script>
import RoboPickerUpload from "../util/RoboPickerUpload";
import { HTTP } from "../../http";
export default {
  name: "ReviewForm",
  components: { RoboPickerUpload },
  props: {
    inspection: {
      type: Object,
    },
    errors: {
      type: Object,
      default: function () {
        return {};
      },
    },
    initial: {
      type: Object,
      default: function () {
        return {
          id: null,
          status: "APPROVED",
          document: "",
          notes: "",
          pub_notes: "",
          draft: true,
        };
      },
    },
  },
  data: function () {
    return {
      form: { ...this.initial },
      moratorium_approved: false,
      documentUrl: this.initial.media ? this.initial.media.document.url : "",
    };
  },
  methods: {
    onSubmit: function (draft) {
      const data = {
        inspection: this.inspection.id,
        draft: draft,
        ...this.payload,
      };
      let method = null;
      if (this.form.id) {
        method = HTTP.put("/api/cityreviews/" + this.form.id + "/", data);
      } else {
        method = HTTP.post("/api/cityreviews/", data);
      }
      method
        .then((result) => {
          this.$store.commit("setMessage", {
            message: "Review successfully updated. Thank you!",
          });
          console.log(result.data);
          this.form.id = result.data.id;
          this.form.modified = result.data.modified;
          if (!data.draft) {
            this.$router.push({ name: "inspection-list" });
          }
        })
        .catch((e) => {
          if (e.status === 409) {
            this.form.id = e.data.review_id;
            this.onSubmit(draft);
          } else {
            this.$store.commit("setMessage", { message: e, variant: "danger" });
          }
        });
    },
    fileuploaded: function (results) {
      this.form.document = results.ssl_url;
      this.documentUrl = results.uploadURL;
    },
  },
  computed: {
    hasError: function () {
      return this.errors != null;
    },
    payload: function () {
      return {
        status: this.form.status,
        pub_notes: this.form.pub_notes,
        notes: this.form.notes,
        document: this.form.document,
      };
    },
    documentFilename: function () {
      if (this.form.document) {
        return this.form.document.split("/").pop();
      } else {
        return "";
      }
    },
    transTemplate: function () {
      return process.env.VUE_APP_TRANSLOADIT_REVIEW_FILE_TEMPLATE;
    },
    moratorium() {
      return ["ADDITIONAL_WORK", "PASSED"].includes(this.form.status);
    },
    disableSave() {
      return this.moratorium && !this.moratorium_approved;
    },
  },
};
</script>
