<template>
    <div class="row border defect mb-2 shadow-sm" :class="{ district: defect.district_added }">
      <div class="col-12">
        <div class="row">
          <span v-if="defect.disputed" class="text-danger">
            <strong>The municipality does not agree with this observation.</strong>
          </span>
          <b-table-simple small ml-2 borderless>
            <b-thead>
              <b-tr>
                <b-th colspan="3">
                  <b-link @click="setMark">
                    <strong v-if="defect.district_added">Municipality Observation #{{ index }} </strong>
                    <strong v-if="!defect.district_added">Inspector Observation #{{ index }} </strong>
                  </b-link>
                </b-th>
              </b-tr>
              <b-tr>
                <b-th>Time</b-th>
                <b-th>Distance</b-th>
                <b-th>Observation Code</b-th>
              </b-tr>
            </b-thead>
            <b-tbody>
              <b-tr>
                <b-td>{{ defect.time }}</b-td>
                <b-td>{{ defect.length }} feet </b-td>
                <b-td>{{ defect.lacp }}</b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
          <b-table-simple small borderless v-if="defect.impact || defect.corrective_action">
            <b-thead>
              <b-tr>
                <b-th v-if="defect.impact">
                  Impact
                </b-th>
                <b-th v-if="defect.corrective_action">
                  Corrective Action
                </b-th>
              </b-tr>
            </b-thead>
            <b-tbody>
              <b-tr>
                <b-td v-if="defect.impact">{{ defect.impact }}</b-td>
                <b-td v-if="defect.corrective_action">{{ defect.corrective_action }}</b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
        </div>
      </div>
    </div>
</template>
<script>
  export default {
    name: 'DefectDetail',
    props: ['defect', 'index'],
    methods: {
      setMark: function(){
        this.$emit('mark-set', this.defect.time)
      }
    }
  }
</script>
<style>
.district {
  background-color: darkseagreen;
}
</style>
