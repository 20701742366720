<template>
  <div :class="'col-sm-' + question.width">
    <fb-input v-if="question.control_type === 'INPUT'" :question="question" @answered="sendAnswer" :initial="initial">
    </fb-input>
    <fb-number v-if="question.control_type === 'NUMBER'" :question="question" @answered="sendAnswer" :initial="initial">
    </fb-number>
    <fb-textarea v-if="question.control_type === 'TEXTAREA'" :question="question" @answered="sendAnswer" :initial="initial">
    </fb-textarea>
    <fb-time v-if="question.control_type === 'TIME'" :question="question" @answered="sendAnswer" :initial="initial">
    </fb-time>
    <fb-date v-if="question.control_type === 'DATE'" :question="question" @answered="sendAnswer" :initial="initial">
    </fb-date>
    <fb-datetime v-if="question.control_type === 'DATETIME'" :question="question" @answered="sendAnswer" :initial="initial">
    </fb-datetime>
    <fb-dropdown v-if="question.control_type === 'DROPDOWN'" :question="question" @answered="sendAnswer" :initial="initial">
    </fb-dropdown>
    <fb-radio v-if="question.control_type === 'RADIO'" :question="question" @answered="sendAnswer" :initial="initial">
    </fb-radio>
    <fb-checkbox v-if="question.control_type === 'CHECKBOX'" :question="question" @answered="sendAnswer" :initial="initial">
    </fb-checkbox>
  </div>
</template>
<script>
import FbInput from './widgets/FbInput.vue'
import FbNumber from './widgets/FbNumber.vue'
import FbTextarea from './widgets/FbTextarea.vue'
import FbTime from './widgets/FbTime.vue'
import FbDate from './widgets/FbDate.vue'
import FbDatetime from './widgets/FbDatetime.vue'
import FbDropdown from './widgets/FbDropdown.vue'
import FbRadio from './widgets/FbRadio.vue'
import FbCheckbox from './widgets/FbCheckbox.vue'

export default {
  components: { FbInput, FbNumber, FbTextarea, FbTime, FbDate, FbDatetime, FbDropdown, FbRadio, FbCheckbox },
  name: 'QuestionMapper',
  props: ['question', 'initial'],
  methods:{
    sendAnswer(answer){
      this.$emit('answered', answer)
    }
  }
}
</script>
