<template>
  <b-input-group>
    <b-form-input ref="copyref" :value="text" readonly @click="select"></b-form-input>
    <b-input-group-append>
      <b-button @click="copy"><font-awesome-icon icon="copy" /> {{ copyText }}</b-button>
    </b-input-group-append>
  </b-input-group>
</template>
<script>
export default {
  name: 'CopyInput',
  props: ['text'],
  data(){
    return {
      copyText: 'Copy'
    }
  },
  methods: {
    select: function(){
      this.$refs.copyref.select()
    },
    copy: function(){
      this.select()
      document.execCommand('copy');
      this.copyText = 'Copied!'
    },
  }
}
</script>
