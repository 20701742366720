<!-- eslint-disable vue/no-mutating-props -->
<template>
  <div class="defect row border m-1 shadow-sm">
    <div class="col">
      <div class="defect-header">
        <slot name="header"></slot>
      </div>
      <div class="row">
        <div class="col-sm-4">
          Metadata
          <b-form-group
            id="group-input-time"
            label="Time"
            label-for="input-time"
            label-cols-sm="5"
          >
            <b-form-input
              id="input-time"
              v-model="defect.time"
              type="number"
              step="0.01"
              size="sm"
              ref="time"
            ></b-form-input>
          </b-form-group>

          <b-form-group
            id="group-input-length"
            label="Distance"
            label-for="input-length"
            label-cols-sm="5"
          >
            <b-form-input
              id="input-length"
              v-model="defect.length"
              type="number"
              step="0.01"
              size="sm"
              placeholder="ft"
              required
            ></b-form-input>
          </b-form-group>
          <b-form-group
            id="group-input-lacp"
            label="Code"
            label-for="input-lacp"
            label-cols-sm="3"
          >
            <b-form-select
              id="input-lacp"
              v-model="defect.lacp"
              size="sm"
              required
            >
              <option :value="null" selected>Select code</option>
              <option value="ROOTS">Roots</option>
              <option value="ROOTS_25">Roots (25%)</option>
              <option value="ROOTS_50">Roots (50%)</option>
              <option value="ROOTS_75">Roots (75%)</option>
              <option value="CHANGE_IN_PIPE">Change in Pipe</option>
              <option value="OUT_OF_ROUND">Out Of Round</option>
              <option value="SAG">SAG</option>
              <option value="OFFSET">Offset</option>
              <option value="INFILTRATION">Infiltration</option>
              <option value="FRACTURE">Fracture</option>
              <option value="CRACK">Crack</option>
              <option value="BROKEN">Broken</option>
              <option value="COLLAPSE">Collapse</option>
              <option value="CONNECTION_12_OCLOCK">
                Connection 12 o'clock
              </option>
              <option value="CONNECTION_9_OCLOCK">Connection 9 o'clock</option>
              <option value="CONNECTION_3_OCLOCK">Connection 3 o'clock</option>
              <option value="CONNECTION_MAIN">Main Connection</option>
              <option value="CONNECTION_WYE">Wye Connection</option>
              <option value="CLEAN_OUT">Clean Out</option>
              <option value="SEPARATED_JOINT">Separated Joint</option>
              <option value="SEPTIC_TANK">Septic Tank</option>
              <option value="CLEANOUT">Cleanout</option>
              <option value="CORROSION">Corrosion</option>
            </b-form-select>
          </b-form-group>
        </div>

        <div class="col">
          <b-form-group
            id="group-input-impact"
            label="Impact"
            label-for="input-impact"
          >
            <b-form-textarea
              id="input-impact"
              v-model="defect.impact"
              size="sm"
              rows="4"
              placeholder="Impact"
            ></b-form-textarea>
          </b-form-group>
        </div>

        <div class="col">
          <b-form-group
            id="group-corrective-action"
            label="Corrective Actions"
            label-for="input-corrective-action"
          >
            <b-form-textarea
              id="input-corrective-action"
              v-model="defect.corrective_action"
              size="sm"
              rows="4"
              placeholder="Corrective Actions"
            ></b-form-textarea>
          </b-form-group>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "DefectFormDistrict",
  props: ["defect"],
  mounted: function () {
    this.$refs["time"].focus();
  },
};
</script>
<style scoped>
.defect {
  background-color: darkseagreen;
}
.form-group {
  margin-bottom: 0rem;
}
</style>
