<template>
  <div>
    <div class="row">
      <div class="col-lg-8">
        <h3 class="mb-3">
          {{ this.$store.getters.fullName }}'s Inspections ({{
            this.$store.getters.company.name
          }})
        </h3>
      </div>
      <div class="col">
        <b-form-group>
          <b-input-group size="sm">
            <b-form-input
              v-model="filter"
              type="search"
              id="filterInput"
              placeholder="Type to Search"
            ></b-form-input>
            <b-input-group-append>
              <b-button :disabled="!filter" @click="filter = ''"
                >Clear</b-button
              >
            </b-input-group-append>
            <b-button
              size="sm"
              class="ml-1"
              @click="refreshTable"
              title="Refresh table"
              ><font-awesome-icon icon="sync"
            /></b-button>
          </b-input-group>
        </b-form-group>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <b-table
          striped
          :items="getInspections"
          :fields="fields"
          primary-key="id"
          :sort-by="sortBy"
          :sort-desc="sortDesc"
          :filter="filter"
          :per-page="perPage"
          :current-page="currentPage"
          table-class="iefix"
          show-empty
          small
          outlined
          responsive
          ref="table"
        >
          <template v-slot:table-busy>
            <b-spinner class="align-middle"></b-spinner>
          </template>
          <template v-slot:empty>
            <div class="text-center">
              <h4 class="d-inline">No inspections yet.</h4>
              <p>
                Inspections you have started will appear in this table. Use the
                button below to start an inspection.
              </p>
              <b-button
                class="ml-auto"
                :to="{ name: 'site-new', query: { type: paramType } }"
                >Start a new inspection</b-button
              >
            </div>
          </template>
          <template v-slot:cell(status)="data">
            <span v-if="data.item.status === 'SUBMITTED'" class="text-success"
              >Submitted</span
            >
            <span v-if="data.item.status === 'IN_PROGRESS'">In Progress</span>
          </template>
          <template v-slot:cell(site_info.owner_last_name)="data">
            {{ data.item.site_info.owner_first_name }}
            {{ data.item.site_info.owner_last_name }}
          </template>
          <template v-slot:cell(cityreview.status)="data">
            <span v-if="data.item.cityreview && !data.item.cityreview.draft">
              <span
                v-if="data.item.cityreview.status == 'APPROVED'"
                class="text-success"
                >Approved</span
              >
              <span
                v-if="data.item.cityreview.status == 'REJECTED'"
                class="text-danger"
                >Rejected</span
              >
              <span
                v-if="data.item.cityreview.status == 'ADDITIONAL_WORK'"
                class="text-warning"
                >Needs additional work</span
              >
              <span
                v-if="data.item.cityreview.status == 'PASSED'"
                class="text-warning"
                >Passed under condition</span
              >
            </span>
            <span v-else>
              <span v-if="data.item.status === 'SUBMITTED'"
                >Pending Review</span
              >
              <span v-else>Not Submitted</span>
            </span>
          </template>
          <template v-slot:cell(actions)="data">
            <b-button
              variant="outline-primary"
              v-if="data.item.status === 'IN_PROGRESS'"
              :to="{
                name: 'inspection-update',
                params: { inspectionId: data.item.id },
              }"
            >
              Open
            </b-button>
            <b-button
              variant="outline-primary"
              v-else
              :to="{
                name: 'inspection-detail',
                params: { inspectionId: data.item.id },
              }"
            >
              Open
            </b-button>
          </template>
        </b-table>
        <div class="overflow-auto">
          <b-pagination
            v-model="currentPage"
            :total-rows="total"
            :per-page="perPage"
            aria-controls="my-table"
            align="right"
          >
          </b-pagination>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { formatDate } from "../../utils.js";
import { HTTP } from "../../http.js";
export default {
  name: "InspectionTableInspector",
  props: ["inspections", "type"],
  data: function () {
    let fields = [
      {
        key: "datetime",
        label: "Time",
        sortable: true,
        formatter: "dateFormat",
      },
      { key: "status", sortable: true, label: "Status" },
      {
        key: "site_info.address",
        sortable: true,
        label: "Address",
        formatter: "address",
      },
      { key: "site_info.owner_last_name", sortable: true, label: "Contact" },
      { key: "actions", label: "" },
    ];
    if (this.type === "DISTRICT") {
      fields.splice(4, 0, {
        key: "cityreview.status",
        sortable: true,
        label: "Municipality Review",
      });
      fields.splice(5, 0, {
        key: "site_info.district.name",
        sortable: true,
        label: "Municipality",
      });
    }
    return {
      sortBy: "datetime",
      sortDesc: true,
      currentPage: 1,
      perPage: 20,
      total: 0,
      filter: "",
      fields: fields,
    };
  },
  methods: {
    keyToOrder(key) {
      switch (key) {
        case "datetime":
          return "date,time,created";
        case "status":
          return "status";
        case "site_info.address":
          return "site__address";
        case "site_info.owner_last_name":
          return "site__owner_last_name";
        case "cityreview.status":
          return "cityreview__status";
        case "site_info.district.name":
          return "site__district__name";
        default:
          return "date,time,created";
      }
    },
    getInspections(ctx) {
      const orderDir = ctx.sortDesc ? "-" : "";
      const orderKey = this.keyToOrder(ctx.sortBy);
      const ordering = orderDir + orderKey.split(",").join("," + orderDir);
      const promise = HTTP.get(
        "/api/inspections/?page=" +
          ctx.currentPage +
          "&search=" +
          ctx.filter +
          "&order=" +
          ordering +
          "&type=" +
          this.type
      );
      return promise.then((data) => {
        const items = data.data.results;
        this.total = data.data.count;
        return items || [];
      });
    },
    address: function (value) {
      let parts = value.split(",");
      const addresscity = parts.splice(0, 2);
      return addresscity.join(",");
    },
    dateFormat: function (value) {
      return formatDate(value.split("T")[0]);
    },
    refreshTable: function () {
      this.$refs.table.refresh();
    },
  },
  computed: {
    paramType: function () {
      return this.type === "POO" ? "po" : "district";
    },
  },
};
</script>
<style>
_:-ms-fullscreen,
:root .iefix {
  table-layout: fixed;
}
@media (max-width: 992px) {
  .smallfix {
    width: 100%;
  }
}
</style>
