<template>
  <b-list-group-item class="question-set-detail" :class="{selectedQuestionSet: selected}">
    <span class="title">
      {{ questionSet.name }}<br/>
      <span class="text-secondary">Questions: {{ questionSet.questionplacement_set.length }}</span>
    </span>
  </b-list-group-item>
</template>
<script>
export default {
  name: 'QuestionSetDetail',
  data(){
    return {}
  },
  props: [
    'questionSet',
    'selected'
  ],
}
</script>
<style>
.question-detail {
  cursor: pointer;
}
.selectedQuestionSet {
  background: silver;
}
</style>
