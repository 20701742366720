<template>
  <div>
    <div class="row table-container">
      <div class="col-md-3 table-cell">
        <question-list></question-list>
      </div>
      <div class="col-md-6">
        <builder-pane></builder-pane>
      </div>
      <div class="col-md-3">
        <question-set-list></question-set-list>
      </div>
    </div>
    <div class="row mt-2">
        <b-card no-body class="fullwidth">
          <b-tabs card>
            <b-tab title="Update Question" active>
              <question-update></question-update>
            </b-tab>
            <b-tab title="Create Question">
              <question-create></question-create>
            </b-tab>
            <b-tab title="Update Quesiton Set">
              <question-set-update></question-set-update>
            </b-tab>
            <b-tab title="Create Question Set">
              <question-set-create></question-set-create>
            </b-tab>
            <b-tab title="Copy Question Set">
              <question-set-copy></question-set-copy>
            </b-tab>
          </b-tabs>
        </b-card>
      </div>
  </div>
</template>
<script>
import BuilderPane from './BuilderPane.vue'
import QuestionCreate from './QuestionCreate.vue'
import QuestionList from './QuestionList.vue'
import QuestionSetCopy from './QuestionSetCopy.vue'
import QuestionSetCreate from './QuestionSetCreate.vue'
import QuestionSetList from './QuestionSetList.vue'
import QuestionSetUpdate from './QuestionSetUpdate.vue'
import QuestionUpdate from './QuestionUpdate.vue'
export default {
  name: 'FormBuilder',
  components: {
    QuestionList,
    QuestionUpdate,
    QuestionCreate,
    QuestionSetList,
    QuestionSetUpdate,
    QuestionSetCreate,
    BuilderPane,
    QuestionSetCopy
  },
  data() {
    return {
    }
  }
}
</script>
<style>
.fullwidth {
  width: 100%;
}
.table-container {
  display: flex;
}

.table-cell {
  flex: 1
}
</style>

