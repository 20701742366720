<template>
  <div class="framed">
    <b-alert v-model="hasError" variant="danger">
      <p>There were errors with your submission. Please correct them below:</p>
      <ul>
        <li v-for="(error, field) in errors" v-bind:key="field">
          {{ field }}: <span v-for="e in error" v-bind:key="e">{{ e }}</span>
        </li>
      </ul>
    </b-alert>
    <b-form @submit="onSubmit">
      <div v-if="!pooinspection">
        <div class="form-row">
          <b-form-group
            id="input-group-10"
            class="col-sm-3"
            label="Municipality"
            label-for="input-10"
          >
            <b-form-select
              id="input-10"
              :options="districtChoices"
              v-model="form.district"
              required
            ></b-form-select>
          </b-form-group>

          <b-form-group
            id="input-group-10"
            class="col-sm-5"
            label="Project"
            label-for="input-10"
            v-show="projectChoices.length > 1"
          >
            <b-form-select
              id="input-10"
              :options="projectChoices"
              v-model="form.project"
            ></b-form-select>
            <b-form-text v-if="selectedDistrict">
              This municipality is running several programs, please make sure
              you have selected the correct program to submit this inspection
              to. If you are unsure which to choose please contact
              {{ selectedDistrict.name }}.
            </b-form-text>
          </b-form-group>
        </div>
      </div>
      <div
        class="form-row"
        v-if="selectedProject && selectedProject.instructions"
      >
        <div class="col">
          <p class="linebreaks">
            <strong>{{ selectedProject.instructions }}</strong>
          </p>
        </div>
      </div>
      <div class="form-row">
        <b-form-group
          id="input-group-5"
          class="col-sm"
          label="Inspection Address"
          label-for="input-5"
        >
          <gmap-autocomplete
            id="input-5"
            @place_changed="setPlace"
            class="form-control"
            required
            placeholder="Address"
            :value="form.address"
            ref="autocomplete"
            v-on:keydown.enter.prevent
          ></gmap-autocomplete>
        </b-form-group>
      </div>
      <div class="form-row">
        <b-form-group
          id="input-group-address-extra"
          class="col-sm-7"
          label="Inspection Address Extra (Optional)"
          label-for="input-address-extra"
        >
          <b-form-input
            id="input-address-extra"
            v-model="form.address_extra"
          ></b-form-input>
        </b-form-group>
      </div>
      <div class="form-row owner-detail">
        <b-form-group
          id="input-group-owner-type"
          class="col-sm"
          label="Property Contact"
          label-for="input-contact-type"
        >
          <b-form-select v-model="form.owner_type" required>
            <b-form-select-option value="">Please Select</b-form-select-option>
            <b-form-select-option value="Property Owner"
              >Property Owner</b-form-select-option
            >
            <b-form-select-option value="Real Estate Agent"
              >Real Estate Agent</b-form-select-option
            >
            <b-form-select-option value="Construction Contact"
              >Construction Contact</b-form-select-option
            >
            <b-form-select-option value="Property Manager"
              >Property Manager</b-form-select-option
            >
          </b-form-select>
        </b-form-group>
        <b-form-group
          id="input-group-1"
          class="col-sm"
          label="First Name"
          label-for="input-1"
        >
          <b-form-input
            id="input-1"
            v-model="form.owner_first_name"
            required
            placeholder="First name"
          ></b-form-input>
        </b-form-group>

        <b-form-group
          id="input-group-2"
          class="col-sm"
          label="Last Name"
          label-for="input-2"
        >
          <b-form-input
            id="input-2"
            v-model="form.owner_last_name"
            required
            placeholder="Last name"
          ></b-form-input>
        </b-form-group>

        <b-form-group
          id="input-group-3"
          class="col-sm"
          label="Phone"
          label-for="input-3"
        >
          <b-form-input
            id="input-3"
            v-model="form.owner_phone"
            v-mask="'(###) ###-####'"
            required
            placeholder="(999) 999-9999"
          ></b-form-input>
        </b-form-group>

        <b-form-group
          id="input-group-4"
          class="col-sm"
          label="Email Address"
          label-for="input-4"
        >
          <b-form-input
            id="input-4"
            v-model="form.owner_email"
            required
            type="email"
            placeholder="Email"
          ></b-form-input>
          <b-input-group
            v-for="(email, index) in other_email_values"
            :key="index"
          >
            <b-form-input
              v-model="email.value"
              type="email"
              class="mt-1"
            ></b-form-input>
            <b-input-group-append class="mt-1">
              <b-button variant="danger" @click="removeEmail(index)"
                ><font-awesome-icon icon="trash"></font-awesome-icon
              ></b-button>
            </b-input-group-append>
          </b-input-group>
          <b-button @click="addEmail" size="sm" class="mt-1"
            >Add additional email</b-button
          >
          <div
            v-if="!pooinspection"
            class="text-muted help-cursor"
            v-b-tooltip.hover
            title="If the property contact doesn't have an email address, please use your company address and share the information sent with the property contact."
          >
            <small>Property contact doesn't have email?</small>
          </div>
        </b-form-group>
      </div>

      <div class="form-row">
        <b-form-group
          id="input-group-8"
          class="col-sm"
          label="Mailing Address"
          label-for="input-8"
        >
          <b-input-group>
            <b-form-input
              id="input-8"
              v-model="form.owner_mailing_address"
              class="form-control"
              :disabled="matchingAddress"
              required
              placeholder="Mailing Address"
            ></b-form-input>
            <b-input-group-append is-text>
              <b-form-checkbox id="input-9" v-model="matchingAddress"
                >Same as property address</b-form-checkbox
              >
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </div>
      <google-map :place="currentPlace" :geolocate="geolocate" />
      <br />
      <div class="text-right">
        <b-button type="submit" variant="primary">Save and Continue</b-button>
      </div>
    </b-form>
  </div>
</template>
<script>
import { gmapApi } from "vue2-google-maps";
import GoogleMap from "../util/GoogleMap";
import { HTTP } from "../../http";
import { mapGetters } from "vuex";
export default {
  name: "SiteForm",
  props: {
    errors: {
      type: Object,
      default: function () {
        return {};
      },
    },
    initial: {
      type: Object,
      default: function () {
        return {
          owner_first_name: "",
          owner_last_name: "",
          owner_email: "",
          other_email: [],
          owner_phone: "",
          owner_type: "",
          address: "",
          address_extra: "",
          lat: null,
          lng: null,
          owner_mailing_address: "",
          num_units: 1,
          district: null,
          project: null,
        };
      },
    },
  },
  components: {
    GoogleMap,
  },
  data: function () {
    const other_email_values = this.initial.other_email.map((e) => ({
      value: e,
    }));
    return {
      currentPlace: null,
      matchingAddress: true,
      other_email_values: other_email_values,
      districtChoices: [{ text: "Select municipality", value: null }],
      districts: [],
      form: { ...this.initial },
    };
  },
  methods: {
    onSubmit: function (event) {
      event.preventDefault();
      this.form.other_email = this.other_email_values
        .map((e) => e.value)
        .filter((e) => e);
      this.$emit("form-submitted", this.form);
    },
    setPlace: function (place) {
      this.form.address = place.formatted_address;
      this.form.lat = place.geometry.location.lat();
      this.form.lng = place.geometry.location.lng();
      this.currentPlace = place;
      if (this.matchingAddress) {
        this.form.owner_mailing_address = place.formatted_address;
      }
    },
    addEmail() {
      this.other_email_values.push({ value: "" });
    },
    removeEmail(index) {
      this.other_email_values.splice(index, 1);
    },
  },
  computed: {
    hasError: function () {
      return this.errors != null;
    },
    pooinspection: function () {
      if (this.inspection) {
        return this.inspection.type === "POO";
      } else {
        return this.$route.query.type === "po";
      }
    },
    geolocate: function () {
      return !this.inspection;
    },
    projectChoices: function () {
      const noproject = [{ text: "No project", value: null }];
      if (this.districts) {
        const district = this.districts.find(
          (d) => d.id === this.form.district
        );
        if (district && district.project_set.length > 0) {
          return district.project_set
            .map((project) => ({
              text: project.name,
              value: project.id,
              instructions: project.instructions,
              priority: project.priority,
            }))
            .sort((a, b) => Number(a.priority) < Number(b.priority));
        }
      }
      return noproject;
    },
    selectedProject() {
      if (this.form.project) {
        return this.projectChoices.find((p) => p.value === this.form.project);
      } else {
        return null;
      }
    },
    selectedDistrict() {
      if (this.form.district) {
        return this.districts.find((d) => d.id === this.form.district);
      } else {
        return null;
      }
    },
    google: gmapApi,
    ...mapGetters(["inspection", "company"]),
  },
  watch: {
    matchingAddress: function () {
      if (this.matchingAddress) {
        this.form.owner_mailing_address = this.form.address;
      }
    },
    "form.district": function () {
      if (this.projectChoices.length > 0) {
        this.form.project = this.projectChoices[0].value;
      }
      if (this.selectedDistrict.requires_property_owner_contact) {
        this.form.owner_type = "Property Owner";
      }
    },
  },
  mounted: function () {
    if (this.form.address) {
      this.$gmapApiPromiseLazy({}).then(() => {
        var geocoder = new this.google.maps.Geocoder();
        geocoder.geocode({ address: this.form.address }, (result, status) => {
          if (status == "OK") {
            this.setPlace(result[0]);
          }
        });
      });
    }
    if (this.company.approved) {
      HTTP.get("/api/districts/").then((response) => {
        this.districts.push(...response.data);
        const choices = response.data.map((district) => {
          return { text: district.name, value: district.id };
        });
        this.districtChoices.push(...choices);
      });
    } else {
      this.districtChoices = [
        { text: "No Approved Districts Available", value: null },
      ];
    }
  },
};
</script>
<style>
.help-cursor {
  cursor: help;
  text-decoration: underline;
  text-decoration-style: dotted;
}
@media (min-width: 768px) {
  #real-property-contact {
    margin-top: -65px;
    display: block;
  }
}
</style>
