import axios from 'axios'
import {router} from './main'

export const HTTP = axios.create({
  baseURL: process.env.VUE_APP_API_ROOT,
  withCredentials: true,
  xsrfCookieName: 'csrftoken',
  xsrfHeaderName: 'X-CSRFTOKEN'
})

HTTP.interceptors.response.use(function (response){
  return response
}, function(error){
  console.log(error)
  if(error.response.status == 401 || error.response.status == 403){
    window.location.replace(process.env.VUE_APP_API_ROOT + '/accounts/login/')
  }
  else if(error.response.status == 404){
    router.replace({name: '404'})
  }
  return Promise.reject(error.response)
})
