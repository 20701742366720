<template>
  <div>
    <div v-if="!inspection">
      <Spinner></Spinner>
    </div>
    <div v-else>
      <div class="row">
        <div class="col">
          <p>
          Upload property photos, PDFs, or any other file you would like to add as supplementary to the inspection.<br/>
          <strong>Do not upload the main CCTV inspection video here.</strong>
          </p>
        </div>
      </div>
      <div class="row framed">
        <div class="col">
          <div class="row">
            <div class="col-sm-6">
             <b-alert v-model="hasError" variant="danger">
                <p>There were errors with your submission. Please correct them below:</p>
                <ul>
                  <li v-for="(error, field) in errors" v-bind:key="field">
                    {{ field }}: <span v-for="e in error" v-bind:key="e">{{ e }}</span>
                  </li>
                </ul>
              </b-alert>
              <MediaUpload media-type="ATTACHMENT" :inspection="inspection" @upload-complete="fileuploaded"></MediaUpload>
            </div>
            <div class="col-sm-6">
              <p v-show="!this.inspection.media_set.length">Uploaded attachments will appear here.</p>
              <PhotoGallery :media="this.inspection.media_set" @media-delete="deleteMedia" :readonly="false"></PhotoGallery>
            </div>
          </div>
          <div class="row">
            <div class="col text-right">
              <b-button id="skip" :to="{name: 'inspection-media', params: { inspectionId: inspectionId }}">Skip
              </b-button>

              <b-button id="continue" :disabled="!media.uploaded" variant="primary" class="ml-1"
                        :to="{ name: 'inspection-media', params: { inspectionId: inspection.id }}">Save and Continue
              </b-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import PhotoGallery from '../util/PhotoGallery.vue'
  import MediaUpload from '../util/MediaUpload.vue'
  import Spinner from '../util/Spinner'
  import {HTTP} from '../../http'
  import {mapGetters} from 'vuex'
  export default {
    name: 'InspectionPropertyPhoto',
    components: {MediaUpload, Spinner, PhotoGallery},
    data: function(){
      return {
        errors: null,
        media:{
          template: process.env.VUE_APP_TRANSLOADIT_STRUCTURE_FORM_UPLOAD,
          uploaded: false
        }
      }
    },
    computed:{
      inspectionId: function(){
        return this.$route.params.inspectionId
      },
      enabledLinks: function(){
        let links = ['siteNew', 'inspectionNew', 'inspectionProperty', 'inspectionMedia' ]
        if(this.inspection.media_set.length > 0){
          links.push('inspectionEval', 'submitToCity')
        }
        return links
      },
      hasError: function(){
        return this.errors != null
      },
      ...mapGetters(['inspection'])
    },
    methods: {
      fileuploaded: function(result){
        let payloads = []
        for (var i = 0; i < result.result.results.length; i++) {
          const r = result.result.results[i]
          const payload = {'inspection': this.inspection.id, 'media_type': result.mediaType, 'mime_type': r.mime, 'url': r.ssl_url}
          payloads.push(payload)
        }
        HTTP.post('/api/media/', payloads).then(response =>{
          this.inspection.media_set = this.inspection.media_set.concat(response.data)
          this.media.uploaded = true
        }).catch(e => {
          this.errors = e.data
        })
      },
      deleteMedia: function(media){
        HTTP.delete('/api/media/' + media.id + '/').then(() => {
          this.inspection.media_set.splice(this.inspection.media_set.indexOf(media), 1)
        }).catch(e => {
          this.errors = e.data
        })
      }
    },
    mounted: function(){
      this.$store.dispatch('fetchInspection', this.inspectionId).then(() => {
        for (var i = 0; i < this.inspection.media_set.length; i++) {
          if(this.inspection.media_set[i].media_type === 'ATTACHMENT'){
            this.media.uploaded = true
            break
          }
        }
      })
    }
  }
</script>
