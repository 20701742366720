<template>
  <ReviewForm :inspection="inspection"></ReviewForm>
</template>
<script>
  import ReviewForm from './ReviewForm'
  export default {
    name: 'NewReview',
    props: ['inspection'],
    components: {ReviewForm},
    data: function(){
      return {}
    }
  }
</script>
