<template>
  <div>
    <div v-if="inspection">
      <p v-if="inspection.site_info.district"><strong>
        {{ inspection.site_info.district_info.name }}<span v-if="inspection.site_info.project_name">,
        {{ inspection.site_info.project_name }}</span>
      </strong></p>
      <InspectionForm :site="inspection.site_info" :errors="errors" @form-submitted="onSubmit" v-if="inspection" :initial="inspection" />
    </div>
    <div v-else>
      <Spinner></Spinner>
    </div>
  </div>
</template>
<script>
  import InspectionForm from './InspectionForm'
  import Spinner from '../util/Spinner'
  import {router} from '../../main'
  import {mapGetters} from 'vuex'
  export default {
    name: 'UpdateInspection',
    components: {
      InspectionForm,
      Spinner
    },
    data: function(){
      return {
        errors: null,
      }
    },
    mounted: function(){
      this.$store.dispatch('fetchInspection', this.inspectionId)
    },
    methods: {
      onSubmit: function(data){
        this.$store.dispatch('updateInspection', data).then(() => {
          router.push('/inspections/' + this.inspection.id + '/property/')
        })
      },
    },
    computed: {
      inspectionId: function(){
        return this.$route.params.inspectionId
      },
      ...mapGetters(['inspection'])
    }
  }
</script>
