<template>
  <div>
    <div v-if="selectedQuestionSet">
      <question-set-form :initial="selectedQuestionSet" @form-submitted="saveQuestionSet"></question-set-form>
    </div>
    <div v-else class="p-5">
      <h4>Please select a question set from the list on the right.</h4>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import QuestionSetForm from './QuestionSetForm.vue'
export default {
  name: 'QuestionUpdate',
  components: {
    QuestionSetForm
  },
  computed: {
    ...mapState({
      selectedQuestionSet: state => state.formbuilder.selectedQuestionSet
    }),
  },
  methods: {
    saveQuestionSet(questionSet) {
      this.$store.dispatch('updateQuestionSet', questionSet)
    }
  }
}
</script>
