<template>
  <div v-if="displayAnswer">
    <dt>{{ question.label }}</dt>
    <dd>{{ formattedAnswer }}</dd>
  </div>
</template>
<script>
export default {
  name: 'AnswerDisplay',
  props: ['question', 'answer'],
  computed: {
    formattedAnswer(){
      if(this.question.value_type === 'BOOLEAN'){
        return this.answer.value === 'false' || this.answer.value === false ? 'No' : 'Yes'
      }else{
        return this.answer.value
      }
    },
    displayAnswer(){
      return this.answer != undefined && this.formattedAnswer != null && this.formattedAnswer != ''
    }
  }
}
</script>
