<template>
  <div class="row">
    <QuestionMapper v-for="question in questionset" v-bind:key="question.id"
                    :question="question"
                    @answered="answered"
                    :initial="answerSet.find(a => a.question === question.id).value">
    </QuestionMapper>
  </div>
</template>
<script>
  import QuestionMapper from '@/components/formbuilder/QuestionMapper.vue'
  import get from 'lodash/get'
  export default {
    name: 'AnswerableSet',
    props: ['questionset', 'inspection', 'initial_answers'],
    components:{ QuestionMapper },
    data(){
      return {
        answerSet: this.questionset.map(q=>
          ({
            question: q.id,
            value: get(this.initial_answers.find(a => a.question === q.id), 'value', null)
          })
        )
      }
    },
    methods: {
      answered(answer){
        const answer_index = this.answerSet.findIndex(q => q.question === answer.question)
        this.answerSet[answer_index].question = answer.question
        this.answerSet[answer_index].value = answer.answer
        this.$emit('answersupdated', this.answerSet)
      }
    }
  }
</script>
