<template>
  <div>
    <div class="embed-responsive embed-responsive-4by3">
      <video
        height="200"
        width="200"
        ref="video"
        class="embed-responsive-item"
        playsinline
      >
        <source :src="source" :type="type" />
      </video>
    </div>
    <div class="row">
      <div class="col">
        <b-form-input
          type="range"
          min="0"
          v-model="currentTime"
          :max="duration"
          @change="onSlide"
          step="0.5"
          number
        ></b-form-input>
      </div>
    </div>
    <div class="row">
      <div class="col text-center">
        <span class="duration"
          >{{ currentTime.toFixed(2) }}/{{ duration.toFixed(2) }}</span
        >
      </div>
    </div>
    <div class="row">
      <div class="col play-buttons">
        <b-button-group>
          <b-button v-if="!playing" variant="success" @click="play"
            ><font-awesome-icon icon="play"
          /></b-button>
          <b-button v-if="playing" variant="warning" @click="pause"
            ><font-awesome-icon icon="pause"
          /></b-button>
          <b-button
            variant="primary"
            :pressed="rate === 0.5"
            @click="setRate(rate === 1 ? 0.5 : 1)"
            >0.5x</b-button
          >
          <b-button
            variant="primary"
            :pressed="rate === 2"
            @click="setRate(rate === 1 ? 2 : 1)"
            >2x</b-button
          >
          <b-button variant="primary" @click="seek(-5 * 25)">
            <font-awesome-icon icon="undo" />
          </b-button>
          <b-button variant="primary" @click="seek(-1)"
            ><font-awesome-icon icon="step-backward"
          /></b-button>
          <b-button variant="primary" @click="seek(1)"
            ><font-awesome-icon icon="step-forward"
          /></b-button>
          <b-button
            variant="primary"
            :pressed="fullscreen === true"
            @click="setFullScreen"
            ><font-awesome-icon icon="expand"></font-awesome-icon
          ></b-button>
        </b-button-group>
      </div>
    </div>
    <div class="row mt-2 text-center">
      <div class="col">
        <b-button size="lg" @click="setMark">Mark Observation</b-button>
      </div>
    </div>
    <div class="row mt-2 text-center">
      <div class="col">
        <b-button
          class="btn-success m-auto mb-2 mt-2"
          :href="source"
          download="forwardlateral.mp4"
        >
          <font-awesome-icon icon="download"></font-awesome-icon> Download Video
        </b-button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "VideoPlayer",
  props: ["source", "type"],
  data: function () {
    return {
      rate: 1,
      playing: false,
      duration: 100,
      currentTime: 0,
      fullscreen: false,
    };
  },
  mounted: function () {
    this.$refs.video.ontimeupdate = this.onTimeUpdate;
    this.$refs.video.ondurationchange = this.loadedMetaData;
  },
  methods: {
    play: function () {
      this.$refs.video.play();
      this.playing = true;
    },
    pause: function () {
      this.$refs.video.pause();
      this.playing = false;
    },
    onTimeUpdate: function () {
      this.currentTime = this.$refs.video.currentTime;
    },
    loadedMetaData: function () {
      this.duration = this.$refs.video.duration;
    },
    onCanPlay: function () {
      this.currentTime = this.$refs.video.currentTime;
    },
    onSlide: function () {
      this.$refs.video.currentTime = this.currentTime;
    },
    setMark: function () {
      this.pause();
      const markTime = this.$refs.video.currentTime;
      this.$emit("mark-set", markTime);
    },
    setTime: function (time) {
      this.pause();
      this.currentTime = time;
      this.$refs.video.currentTime = time;
    },
    setRate: function (rate) {
      this.rate = rate;
      this.$refs.video.playbackRate = this.rate;
    },
    setFullScreen: function () {
      this.fullscreen = !this.fullscreen;
      this.$emit("fullscreen");
    },
    seek: function (frames) {
      this.pause();
      this.setTime(Math.min(this.currentTime + frames / 25));
    },
  },
};
</script>
<style>
.duration {
  font-family: monospace;
  font-size: 0.8em;
}
.play-buttons {
  justify-content: flex-start;
  text-align: left;
}

.play-buttons .btn:focus {
  outline: none;
  box-shadow: none;
  background-color: #007bff;
  border-color: #007bff;
}

@media (min-width: 768px) {
  .play-buttons {
    justify-content: center;
    text-align: center;
  }
}
</style>
