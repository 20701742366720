<template>
  <div class="inspection-detail p-3">
    <div class="row">
      <div class="col-md-6">
        <dl class="row m-1">
          <dt class="col-sm-5">Status:</dt><dd class="col-sm-7"> {{ inspection.status_display }}</dd>
          <dt v-if="inspection.submitted" class="col-sm-5">Submitted:</dt><dd v-if="inspection.submitted" class="col-sm-7"> {{ inspection.submitted | formatDateTime }}</dd>
          <dt class="col-sm-5">Date of Inspection:</dt><dd class="col-sm-7"> {{ inspection.date | formatDate }}</dd>
          <dt class="col-sm-5">Time of Inspection:</dt><dd class="col-sm-7"> {{ inspection.time | formatTime }}</dd>
          <dt v-if="inspection.site_info.project_name" class="col-sm-5">Project:</dt>
          <dd v-if="inspection.site_info.project_name" class="col-sm-7">{{ inspection.site_info.project_name }}</dd>

        </dl>
        <hr/>
        <dl class="m-1 p-3 mt-2">
          <AnswerDisplay v-for="question in dynamicQuestions"
                        :key="question.id"
                        :question="question"
                        :answer="inspection.answer_set.find(a => a.question === question.id)">
          </AnswerDisplay>
        </dl>
        <hr/>
        <dl class="m-1 p-3 mt-2">
          <dt>Inspector's Summary:</dt>
          <dd style="white-space: pre-wrap;">{{ inspection.summary }}</dd>
          <span v-if="pooinspection">
            <dt>Next Steps</dt>
            <dd style="white-space: pre-wrap;">{{ inspection.next_steps }}</dd>
          </span>
          <dt>Signed:</dt>
          <dd><b-img :src="inspection.signature_pad"/></dd>
        </dl>
      </div>
      <div class="col mr-3">
        <WaypointMap :center="map.center" :inspection="inspection" :readonly="true"></WaypointMap>
        <hr/>
        <div class="text-muted help-cursor text-right mb-2" v-b-tooltip.hover
          title="Please re-upload property photos on step 3 in the order in which you'd like them to appear.">
          <small>Photos out of order?</small>
        </div>
        <PhotoGallery v-if="inspection.media_set.length > 0" :media="inspection.media_set" :readonly="true" display="vertical"></PhotoGallery>
      </div>
    </div>
  </div>
</template>
<script>
  import WaypointMap from '../util/WaypointMap'
  import PhotoGallery from '../util/PhotoGallery.vue'
  import AnswerDisplay from '@/components/formbuilder/AnswerDisplay.vue'
  export default {
    name: 'InspectionFormDetail',
    props: ['inspection'],
    components: {PhotoGallery, WaypointMap, AnswerDisplay},
    data: function(){
      return {
        map: {
          center: {
            lat: this.inspection.site_info.lat,
            lng: this.inspection.site_info.lng
          }
        }
      }
    },
    computed: {
      pooinspection: function(){
        return this.inspection.type === 'POO'
      },
      dynamicQuestions: function(){
        let placementCopy = [...this.inspection.question_set.questionplacement_set]
        const placements = placementCopy.sort((a, b) => a.order - b.order)
        return placements.map(p => p.question)
      }
    },
    filters: {
      yesNo: function(value){
        if(value === null){
          return 'N/A'
        }
        return value ? 'Yes' : 'No'
      }
    }
  }
</script>
