<template>
  <div class="row">
    <div class="col">
      <div v-if="review && !review.draft">
        <div v-if="review.status === 'APPROVED'">
          <p>
            The sanitation municipality has approved this inspection. No further
            actions are required!
          </p>
        </div>
        <div v-else>
          <p v-if="review.status === 'PASSED'">
            An additional step is required for final approval, but the
            municipality has passed your first inspection.
          </p>
          <p v-else>
            Your municipality has determined that your sewer lateral on your
            property needs repair or another inspection. Please review the notes
            from the municipality and/or the attached letter below for next
            steps.
          </p>
          <p><strong>Next Steps</strong></p>
          <p>If you are the property owner:</p>
          <ol>
            <li>
              Share this inspection with your chosen contractors during the
              bidding process: <br />
              <CopyInput :text="inspection.frontend_link"></CopyInput>
            </li>
          </ol>
        </div>
      </div>
      <span v-else>
        <p class="mt-2">
          The municipality has not yet submitted a review. We will notify when a
          review is available.
        </p>
      </span>
      <div
        class="district-materials"
        v-if="review.pub_notes || review.media.document.url"
      >
        <div v-if="review.pub_notes">
          <p><strong>Notes from the municipality:</strong></p>
          <p style="white-space: pre-wrap">{{ review.pub_notes }}</p>
        </div>
        <p v-if="review.media.document.url">
          <b-link
            :href="review.media.document.url"
            target="_blank"
            rel="noopener noreferrer"
            ><strong>Download Municipality Letter</strong></b-link
          >
        </p>
      </div>
    </div>
  </div>
</template>
<script>
import CopyInput from "@/components/util/CopyInput";
import moment from "moment";
export default {
  name: "ReviewDetail",
  props: ["review", "inspection"],
  components: { CopyInput },
  data: function () {
    return {
      copyText: "Copy",
    };
  },
  filters: {
    dateTimeFormat: function (value) {
      return moment(value).format("M/D/YYYY h:mm a");
    },
  },
};
</script>
<style>
.district-materials {
  border: 1px dashed;
  background-color: beige;
  padding: 10px;
  margin-top: 10px;
}
</style>
