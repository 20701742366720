<template>
  <div>
    <gmap-map
      :center="center"
      :zoom="20"
      style="width:100%;  height: 400px;"
    >
      <gmap-marker
        v-if="marker"
        :position="marker.position"
        @click="center=marker.position"
      ></gmap-marker>
    </gmap-map>
  </div>
</template>

<script>
export default {
  name: 'GoogleMap',
  props: ['place', 'geolocate'],
  data() {
    return {
      center: { lat: 34.4228745, lng: -119.6978805 },
      marker: null
    };
  },

  mounted() {
    if(this.geolocate){
      this.doGeolocate();
    }
  },
  watch: {
    place: function(){
      if(this.place){
        const center = {
          lat: this.place.geometry.location.lat(),
          lng: this.place.geometry.location.lng()
        };
        this.marker = {position: center}
        this.center = center
      }
      else{
        this.marker = null
      }
    }
  },
  methods: {
    doGeolocate: function() {
      navigator.geolocation.getCurrentPosition(position => {
        this.center = {
          lat: position.coords.latitude,
          lng: position.coords.longitude
        };
      });
    }
  }
};
</script>
