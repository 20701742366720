<template>
  <b-list-group-item class="question-detail" :class="{selectedQuestion: selected}">
    <span class="title">
      {{ question.name }}<br/>
      <span class="text-secondary">{{ question.label }}</span>
    </span>
  </b-list-group-item>
</template>
<script>
export default {
  name: 'QuestionDetail',
  data(){
    return {}
  },
  props: [
    'question',
    'selected'
  ],
}
</script>
<style>
.question-detail {
  cursor: pointer;
  padding: .25rem 0.5rem;
  line-height: 100%;
}
.selectedQuestion {
  background: silver;
}
</style>
