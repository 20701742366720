<template>
  <div>
    <div v-if="site">
      <b-alert variant="info" show="true">
        You are updating the contact and location information of this property.
        Note that changing information here will change it for all
        inspections of this site.
      </b-alert>
      <SiteForm :errors="errors" :initial="site" @form-submitted="onSubmit" v-if="site"/>
    </div>
    <div v-else>
      <Spinner></Spinner>
    </div>
  </div>
</template>
<script>
  import SiteForm from './SiteForm'
  import Spinner from '../util/Spinner'
  import {HTTP} from '../../http'
  import {router} from '../../main'
  export default {
    name: 'UpdateSite',
    components: {
      SiteForm,
      Spinner
    },
    data: function(){
      return {
        errors: null,
        site: null,
        inspection: null
      }
    },
    methods: {
      onSubmit: function(data){
        HTTP.put('/api/sites/' + this.siteId + '/', data).then(() =>{
          if(this.$route.query.inspectionId){
            const inspectionId = this.$route.query.inspectionId
            this.$store.dispatch('fetchInspection', inspectionId).then(() => {
              router.push({name: 'inspection-update', params: { inspectionId: inspectionId }})
            })
          }else{
            router.push({'name': 'inspection-list'})
          }
        }).catch(e => {
          this.errors = e.data
          document.body.scrollTop = document.documentElement.scrollTop = 0
        })
      },
    },
    mounted: function(){
      HTTP.get('/api/sites/' + this.siteId + '/').then(response => {
        this.site = response.data
      }).catch(e => {
        this.errors = e.data
      })
      if(this.inspectionId){
        this.$store.dispatch('fetchInspection', this.inspectionId)
      }
    },
    computed: {
      inspectionId: function(){
        return Number(this.$route.query.inspectionId)
      },
      siteId: function(){
        return this.$route.params.siteId
      }
    }
  }
</script>
