<template>
  <div>
    <b-select v-model="selectedQuestionSet" :options="setOptions"></b-select>
    <b-button @click="copyQuestionSet" :disabled="!selectedQuestionSet">Copy</b-button>
  </div>
</template>
<script>
import { mapState } from 'vuex'
export default {
  name: 'QuestionSetCopy',
  data() {
    return {
      selectedQuestionSet: null
    }
  },
  methods: {
    copyQuestionSet() {
      this.$store.dispatch('copyQuestionSet', this.questionSet)
    }
  },
  computed: {
    ...mapState({
      questionSets: state => state.formbuilder.questionSets,
    }),
    setOptions(){
      return this.questionSets.map(qs => {
          return { value: qs.id, text: qs.name }
      })
    },
    questionSet(){
      return this.questionSets.find(qs => qs.id === this.selectedQuestionSet)
    }
  }
}
</script>
