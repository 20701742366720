<template>
  <div class="root">
    <SiteForm :errors="errors" @form-submitted="onSubmit" />
  </div>
</template>
<script>
  import SiteForm from './SiteForm'
  import {HTTP} from '../../http'
  import {router} from '../../main'
  export default {
    name: 'Addsite',
    components: {
      SiteForm,
    },
    data: function(){
      return {
        errors: null,
      }
    },
    mounted: function(){
      this.$store.dispatch('clearInspection')
    },
    methods: {
      onSubmit: function(data){
        HTTP.post('/api/sites/', data).then(response =>{
          router.push('/inspections/' + response.data.inspectionId + '/update/')
        }).catch(e => {
          this.errors = e.data
          document.body.scrollTop = document.documentElement.scrollTop = 0
        })
      },
    }
  }
</script>
