<template>
  <div>
    <div ref="uppy" :id="uppyId"></div>
    <b-button @click="resetUpload" variant="danger" size="sm" v-show="hasUploaded">Remove Media</b-button>
  </div>
</template>
<script>
  import 'whatwg-fetch' // ie 11 support
  import Robodog from '@uppy/robodog'
  import '@uppy/core/dist/style.css'
  import '@uppy/dashboard/dist/style.css'
  export default {
    name: 'Uppy',
    props: ['mediaType', 'params', 'fields', 'signature'],
    data: function(){
      return {
        uppy: null,
        hasUploaded: false,
        fileCount: 0
      }
    },
    computed: {
      uppyId: function(){
        return 'uppy_' + this.mediaType
      }
    },
    mounted: function(){
      const allowedFileTypes = {
        'VIDEO': ['video/*', 'application/octet-stream'],
        'PROPERTY_PHOTO': ['image/*'],
        'ATTACHMENT': ['*/*']
      }
      this.uppy = Robodog.dashboard(this.$refs.uppy, {
        params: this.params,
        signature: this.signature,
        fields: this.fields,
        waitForEncoding: true,
        autoProceed: false,
        allowMultipleUploads: false,
        showProgressDetails: true,
        showLinkToFileUploadResult: false,
        height: 150,
        restrictions:{
          allowedFileTypes: allowedFileTypes[this.mediaType],
          maxNumberOfFiles: 10,
          minNumberOfFiles: 1
        },
        providers: ['dropbox', 'google-drive'],
        onBeforeFileAdded: (currentFile) => {
          if(this.mediaType == 'VIDEO'){
            const modifiedFile = Object.assign(
              {},
              currentFile,
              { name: this.mediaType + '_' + (this.fileCount + 1) }
            )
            this.fileCount += 1
            return modifiedFile
          }else if(this.mediaType == 'ATTACHMENT'){
            if(currentFile.type.includes('video')){
              this.$store.commit('setMessage', {message: 'Please upload videos in the next step.', variant: 'danger'})
              return false
            }
          }else{
            return currentFile
          }
        }
      })

      this.uppy.on('complete', (result) => {
        if(result.failed.length > 0){
          this.$emit('upload-failed', result.failed)
        }else{
          this.$emit('upload-success', result)
          this.hasUploaded = true
        }
      })
    },
    methods: {
      resetUpload: function(){
        this.uppy.reset()
        this.hasUploaded = false
        this.$emit('upload-reset')
      }
    }
  }
</script>
<style>
.uppy-Dashboard-inner {
  min-height: 350px;
}
</style>
