<template>
  <div>
    <h1>Properties</h1>
    <b-table striped hover :items="sites" :fields="fields" primary-key="id" :sort-by="sortBy" :sort-desc="sortDesc">
      <template slot="fullname" slot-scope="data">
        {{ data.item.owner_first_name }} {{ data.item.owner_last_name }}
      </template>
    </b-table>
  </div>
</template>
<script>
import {HTTP} from '../../http'
export default {
  name: 'Sites',
  data: function(){
    return {
      sortBy: 'inspection_date',
      sortDesc: true,
      fields: [
        'address',
        { key: 'fullname', label: 'Owner' },
        { key: 'owner_email', label: 'Email'},
        { key: 'owner_phone', label: 'Phone'},
        'num_units'
      ],
      sites: []
    }
  },
  mounted: function(){
    HTTP.get('/api/sites/').then(response => {
      this.sites = response.data
    }).catch(e => {
      console.log(e);
    })
  }
}
</script>
