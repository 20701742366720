<template>
  <div class="row">
    <div class="media-wrap mb-2 text-center" :class="display === 'grid' ? 'col-4' : 'col-12'" v-for="m in sortedMedia" v-bind:key="m.id">
      <div class="media-img p-1 text-center border shadow-sm">
        <span class="media-img-delete border border-danger shadow-sm rounded-circle" v-if="!readonly">
          <b-link class="text-dark" @click="deleteMedia(m)"><font-awesome-icon icon="times" fixed-width></font-awesome-icon></b-link>
        </span>
        <b-link @click="currentMedia = m" v-b-modal.photo-modal>
          <b-img v-if="m.mime_type.indexOf('image') > -1" :src="m.signed_url" :class="{'fixed-size': display === 'grid'}" fluid></b-img>
          <div v-else class="filethumb"><font-awesome-icon icon="file" size="4x"/><br/>{{ m.url.split('/').pop() }}</div>
        </b-link>
      </div>
    </div>
    <b-modal id="photo-modal" :hide-footer="true" size="lg">
      <div class="text-center" v-if="currentMedia">
        <b-img v-if="currentMedia.mime_type.indexOf('image') > -1" :src="currentMedia.signed_url" class="img-fluid"></b-img>
        <a v-else :href="currentMedia.signed_url">{{ currentMedia.url.split('/').pop() }} <font-awesome-icon icon="download"/></a>
      </div>
    </b-modal>
  </div>
</template>
<script>
export default {
  name: 'PhotoGallery',
  props: {
    media: {
      type: Array,
      required: true,
    },
    readonly: {
      type: Boolean,
      required: false,
      default: true,
    },
    display: {
      type: String,
      required: false,
      default: 'grid'
    }
  },
  data: function(){
    return {
      currentMedia: null
    }
  },
  methods: {
    deleteMedia: function(media){
      this.$emit('media-delete', media)
    }
  },
  computed: {
    filteredMedia: function() {
      return this.media.filter(media => {return media.mime_type.indexOf('video') < 0})
    },
    sortedMedia: function(){
      return [...this.filteredMedia].sort((a)=>{
        if(a.mime_type.indexOf('image') > -1){
          return -1
        }else{
          return 1
        }
      })
    }
  }
}
</script>
<style>
.media-img {
  background: white;
  display:inline-block;
}
.fixed-size {
  max-height: 150px;
  vertical-align: middle;
}
.media-img-delete {
  background: red;
  position: absolute;
  z-index: 100;
}
.media-img .media-img-delete {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s, opacity 0.2s linear;

}
.media-img:hover .media-img-delete {
  visibility: visible;
  opacity: 1;
}
.filethumb {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 15ch;
}
</style>
