<template>
  <div class="col-md-5">
    <p v-if="currentCard">Current Card: {{ currentCard.card.brand }} ending in {{ currentCard.card.last4}}</p>
    <div ref="card" class="form-control"></div>
    <div class="text-danger" v-show="cardError">{{ cardError }}</div>
    <b-button @click="saveCard" class="mt-4">Save Payment Method</b-button>
  </div>
</template>
<script>
  let stripe = Stripe(process.env.VUE_APP_STRIPE_PUBLISH_KEY), // eslint-disable-line no-undef
  elements = stripe.elements(),
  card = undefined;
  import { HTTP } from '@/http'
  export default {
    name: 'Payment',
    props: [],
    data() {
      return {
        clientSecret: '',
        currentCard: null,
        cardError: '',
        user: null,
      }
    },
    mounted() {
      card = elements.create('card');
      card.mount(this.$refs.card);
    },
    created(){
      this.fetchUserAndCard()
    },
    methods: {
      fetchUserAndCard(){
        HTTP.get('/api/accounts/paymentsetup/').then(response => {
          this.clientSecret = response.data.client_secret
          this.currentCard = response.data.current_card
        }).catch(e => {
          console.log(e)
        })
        HTTP.get('/api/accounts/user/').then(response => {
          this.user = response.data
        })
      },
      saveCard(){
        stripe.confirmCardSetup(
          this.clientSecret,
          {
            payment_method: {
              card: card,
              billing_details: {
                name: this.user.full_name,
                phone: this.user.phone,
                email: this.user.email,
                address: {
                  line1: this.user.profile.address_line_1,
                  line2: this.user.profile.address_line_2,
                  city: this.user.profile.address_city,
                  country: 'US',
                  postal_code: this.user.profile.address_zip,
                  state: this.user.profile.address_state,
                }
              }
            }
          }
        ).then(result => {
          if(result.error){
            this.cardError = result.error.message
            console.log(result.error)
          }else{
            this.cardError = ''
            this.$store.commit('setMessage', {message: 'Card Updated Successfully'})
            this.fetchUserAndCard()
          }
        })
      }
    }
  }
</script>
