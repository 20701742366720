<template>
  <div>
    <div v-if="!inspection">
      <Spinner></Spinner>
    </div>
    <div v-else>
      <div class="row">
        <div
          class="mb-5"
          :class="fullscreen ? 'col-sm-12' : 'col-xl-4 col-lg-12'"
        >
          <VideoPlayer
            v-if="last_video"
            :source="last_video.signed_url"
            v-on:mark-set="setMark"
            ref="video"
            @fullscreen="togglefullscreen"
          >
          </VideoPlayer>
          <div v-else>
            <div v-if="inspection.media_error">
              <p>There was an error processing your media.</p>
              <pre>{{ inspection.media_error }}</pre>
              <p>
                Please make sure your video file is playable, or contact
                support.
              </p>
            </div>
            <div v-else>
              Hang tight! Your video is processing.
              <Spinner></Spinner>
            </div>
          </div>
        </div>
        <div class="col">
          <b-alert v-model="hasError" variant="danger">
            <p>
              There were errors with your submission. Please correct them below:
            </p>
            <ul>
              <li v-for="(error, field) in errors" v-bind:key="field">
                {{ field }}:
                <span v-for="e in error" v-bind:key="e">{{ e }}</span>
              </li>
            </ul>
          </b-alert>
          <b-form @submit.prevent="saveAndContinue" ref="defects-form">
            <div class="col pt-2 defects d-block border">
              <div v-if="!defects.length" class="col-md-6 ml-auto mr-auto pt-5">
                Use the Mark Observation button to add an observation
              </div>
              <div v-for="(defect, i) in defects" :key="i">
                <div v-if="districtUser">
                  <div v-if="defect.district_added">
                    <DefectFormDistrict :defect="defect">
                      <template v-slot:header>
                        <span
                          ><b-link @click="setTime(defect.time)"
                            >Municipality Observation #{{ i + 1 }}</b-link
                          ></span
                        >
                        <b-button
                          class="btn-danger btn-sm remove float-right"
                          @click="removeDefect(i)"
                          title="Remove"
                        >
                          <font-awesome-icon icon="trash"></font-awesome-icon>
                        </b-button>
                      </template>
                    </DefectFormDistrict>
                  </div>
                  <div v-else>
                    <DefectDetailDistrict :defect="defect">
                      <template v-slot:header>
                        <span
                          ><b-link @click="setTime(defect.time)"
                            >Inspector Observation #{{ i + 1 }}</b-link
                          ></span
                        >
                      </template>
                    </DefectDetailDistrict>
                  </div>
                </div>
                <div v-else>
                  <DefectFormInspector
                    :defect="defect"
                    v-if="!defect.district_added"
                    :pooinspection="pooinspection"
                  >
                    <template v-slot:header>
                      <span
                        ><strong
                          ><b-link @click="setTime(defect.time)"
                            >Inspector Observation #{{ i + 1 }}</b-link
                          ></strong
                        ></span
                      >
                      <b-button
                        class="btn-danger btn-sm remove float-right"
                        @click="removeDefect(i)"
                        title="Remove"
                      >
                        <font-awesome-icon icon="trash"></font-awesome-icon>
                      </b-button>
                    </template>
                  </DefectFormInspector>
                </div>
              </div>
            </div>
            <div class="row submit-buttons">
              <div class="col text-right">
                <b-button
                  type="submit"
                  variant="primary"
                  :disabled="defects.length < 1"
                  >{{ savetext }}</b-button
                >
              </div>
            </div>
          </b-form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { HTTP } from "../../http";
import VideoPlayer from "../util/VideoPlayer";
import Spinner from "../util/Spinner";
import DefectFormInspector from "./DefectFormInspector.vue";
import DefectFormDistrict from "./DefectFormDistrict.vue";
import DefectDetailDistrict from "./DefectDetailDistrict.vue";
export default {
  name: "DefectCreate",
  components: {
    VideoPlayer,
    Spinner,
    DefectFormInspector,
    DefectFormDistrict,
    DefectDetailDistrict,
  },
  props: ["inspection", "savetext"],
  data: function () {
    return {
      errors: null,
      defects: [],
      fullscreen: false,
      last_video: null,
    };
  },
  methods: {
    setMark: function (markTime) {
      this.saveDefects()
        .then(() => {
          this.defects.sort((a, b) => {
            return a.time - b.time;
          });
          this.defects.push({
            inspection: this.inspection.id,
            time: markTime.toFixed(2),
            legnth: null,
            lacp: null,
            impact: "",
            corrective_action: "",
            district_added: this.districtUser ? true : false,
            disputed: false,
          });
          this.$emit("defectadded", this.defects);
        })
        .catch((e) => {
          console.log(e);
        });
    },
    setTime: function (time) {
      this.$refs["video"].setTime(time);
    },
    saveDefects: function () {
      return new Promise((resolve, reject) => {
        const completeDefects = this.defects.filter(
          (defect) => defect.length != null && defect.lacp
        );
        if (completeDefects.length < 1) {
          resolve([]);
        } else {
          HTTP.post("/api/defects/", completeDefects)
            .then((response) => {
              this.defects = response.data;
              this.$emit("defectssaved", this.defects);
              resolve(this.defects);
            })
            .catch((e) => {
              this.errors = e.data;
              reject(e);
            });
        }
      });
    },
    saveAndContinue: function () {
      this.saveDefects().then(() => {
        this.$emit("saveandcontinue", this.defects);
      });
    },
    removeDefect: function (index) {
      this.defects.splice(index, 1);
    },
    checkVideoProcessed: function () {
      setInterval(() => {
        if (this.last_video) {
          return;
        } else {
          HTTP.get("/api/inspections/" + this.inspection.id + "/").then(
            (response) => {
              this.last_video = response.data.last_video;
            }
          );
        }
      }, 1000);
    },
    togglefullscreen: function () {
      this.fullscreen = !this.fullscreen;
    },
  },
  computed: {
    hasError: function () {
      return this.errors != null;
    },
    districtUser: function () {
      return this.$store.getters.districtUser;
    },
    pooinspection: function () {
      return this.inspection.type === "POO";
    },
  },
  mounted: function () {
    this.defects = this.inspection.defects;
    this.last_video = this.inspection.last_video;
    if (!this.last_video) {
      this.checkVideoProcessed();
    }
  },
  watch: {
    defects: {
      handler: function () {
        this.$emit("defectchanged", this.defects);
      },
      deep: true,
    },
  },
};
</script>
<style>
.defect-header {
  margin: 5px 0px;
}
.defect {
  margin: 5px 0px;
  background-color: #ffffff;
  padding: 5px 5px;
}
.remove {
  font-size: 0.75em;
}
.defects {
  height: 50vh;
  overflow: auto;
  margin-bottom: 10px;
  background-color: #f9f9f9;
}

.submit-buttons {
  justify-content: flex-start;
  text-align: left;
}

@media (min-width: 768px) {
  .submit-buttons {
    justify-content: flex-end;
  }
}
</style>
