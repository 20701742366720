<template>
  <div>
    <div v-if="!inspection">
      <Spinner></Spinner>
    </div>
    <div v-else>
      <DefectCreate :inspection="inspection" @saveandcontinue="saveandcontinue" savetext="Save and Continue"></DefectCreate>
    </div>
  </div>
</template>
<script>
  import Spinner from '../util/Spinner'
  import DefectCreate from '../defects/DefectCreate'
  import {mapGetters} from 'vuex'
  export default {
    name: 'InspectionEval',
    components: {DefectCreate, Spinner},
    data: function(){
      return {
        errors: null,
      }
    },
    methods: {
      saveandcontinue: function(){
        this.$router.push({name: 'submit-to-city'})
      }
    },
    computed:{
      inspectionId: function(){
        return this.$route.params.inspectionId
      },
      hasError: function(){
        return this.errors != null
      },
      ...mapGetters(['inspection'])
    },
    mounted: function(){
      this.$store.dispatch('fetchInspection', this.inspectionId)
    }
  }
</script>
