
import Vue from 'vue'

import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon, FontAwesomeLayers, FontAwesomeLayersText } from '@fortawesome/vue-fontawesome'
import {
  faPlay, faPause, faStickyNote, faEnvelope, faPhone, faHome, faLock, faIdBadge, faSync, faPrint,
  faDownload, faTrash, faExpand, faEdit, faStepForward, faStepBackward, faCopy, faFilePdf, faTimes,
  faGripLines, faMapMarkerAlt, faFileExcel, faMobileAlt, faTablet, faFile, faFastBackward, faUndo,
  faShare, faLink
} from '@fortawesome/free-solid-svg-icons'
import {
  faFirefox, faChrome, faSafari
} from '@fortawesome/free-brands-svg-icons'

library.add(
  faPlay, faPause, faStickyNote, faEnvelope, faPhone, faHome, faLock, faIdBadge, faSync, faPrint,
  faDownload, faTrash, faExpand, faEdit, faFirefox, faChrome, faSafari, faStepForward, faStepBackward,
  faCopy, faFilePdf, faTimes, faGripLines, faMapMarkerAlt, faFileExcel, faMobileAlt, faTablet, faFile,
  faFastBackward, faUndo, faShare, faLink
)

Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.component('font-awesome-layers', FontAwesomeLayers)
Vue.component('font-awesome-layers-text', FontAwesomeLayersText)
