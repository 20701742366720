<template>
  <div>
    <span v-show="!showform">
      {{ address_extra }}<b-link class="ml-2" @click="formtoggle"><font-awesome-icon icon="edit" />
      <span v-if="!address_extra">Edit extra address information</span>
      </b-link>
      </span>
    <b-form-group
      id="input-group-address-extra"
      v-show="showform"
    >
      <b-input-group>
      <b-form-input
        id="input-address-extra"
        v-model="address_extra"
      ></b-form-input>
      <b-input-group-append>
        <b-button class="btn btn-sm" @click="save">Save</b-button>
      </b-input-group-append>
    </b-input-group>
    </b-form-group>
  </div>
</template>
<script>
  import {HTTP} from '../../http'
  export default {
    name: 'AddressExtra',
    props: ['site'],
    data: function(){
      return {
        address_extra: this.site.address_extra,
        showform: false
      }
    },
    methods:{
      save: function(event){
        event.preventDefault()
        const payload = {address_extra: this.address_extra}
        HTTP.patch('/api/sites/' + this.site.id + '/', payload).then(response =>{
          this.address_extra = response.data.address_extra
          this.showform = false
        }).catch(e =>{
          console.log(e)
        })

      },
      formtoggle: function(){
        this.showform = !this.showform
      }
    }
  }
</script>
