import Vue from "vue";
import Vuex from "vuex";
import "./plugins/bootstrap-vue";
import "./fontawesome";
import VueRouter from "vue-router";
import * as VueGoogleMaps from "vue2-google-maps";
import VueMask from "v-mask";
import "core-js/stable";
import "regenerator-runtime/runtime";
import moment from "moment";

import App from "./App.vue";
import Home from "./components/Home.vue";
import Account from "./components/account/Account.vue";
import Sites from "./components/sites/Sites.vue";
import NewSite from "./components/sites/NewSite.vue";
import UpdateSite from "./components/sites/UpdateSite.vue";
import Inspections from "./components/inspections/Inspections.vue";
import UpdateInspection from "./components/inspections/UpdateInspection.vue";
import InspectionPropertyPhoto from "./components/inspections/InspectionPropertyPhoto.vue";
import InspectionMedia from "./components/inspections/InspectionMedia.vue";
import InspectionEval from "./components/inspections/InspectionEval.vue";
import SubmitToCity from "./components/inspections/SubmitToCity.vue";
import LongTermPlan from "./components/inspections/LongTermPlan.vue";
import InspectionDetail from "./components/inspections/InspectionDetail.vue";
import Admin from "./components/admin/Admin.vue";
import FormBuilder from "./components/formbuilder/FormBuilder.vue";
import Help from "./components/Help.vue";
import Legal from "./components/Legal.vue";
import NotFound from "./components/NotFound.vue";
import { HTTP } from "./http";
import { formatDate } from "./utils";
import { formbuilder } from "@/store/formbuilder";

console.log("Using backed: " + process.env.VUE_APP_API_ROOT);

Vue.config.productionTip = false;
Vue.use(VueRouter);
Vue.use(Vuex);
Vue.use(VueMask);
Vue.use(VueGoogleMaps, {
  load: {
    key: process.env.VUE_APP_GOOGLE_API_KEY,
    libraries: "places",
  },
});

const routes = [
  { path: "/", component: Home, name: "home" },
  { path: "/admin/", component: Admin, name: "admin" },
  { path: "/formbuilder/", component: FormBuilder, name: "formbuilder" },
  { path: "/help/", component: Help, name: "help" },
  { path: "/legal/", component: Legal, name: "legal" },
  { path: "/account/", component: Account, name: "account" },
  { path: "/inspections/", component: Inspections, name: "inspection-list" },
  {
    path: "/inspections/:inspectionId/",
    component: InspectionDetail,
    name: "inspection-detail",
  },
  {
    path: "/inspections/:inspectionId/update/",
    component: UpdateInspection,
    name: "inspection-update",
  },
  {
    path: "/inspections/:inspectionId/property/",
    component: InspectionPropertyPhoto,
    name: "inspection-property",
  },
  {
    path: "/inspections/:inspectionId/media/",
    component: InspectionMedia,
    name: "inspection-media",
  },
  {
    path: "/inspections/:inspectionId/evaluate/",
    component: InspectionEval,
    name: "inspection-eval",
  },
  {
    path: "/inspections/:inspectionId/submit/",
    component: SubmitToCity,
    name: "submit-to-city",
  },
  {
    path: "/inspections/plan/",
    component: LongTermPlan,
    name: "long-term-plan",
  },
  { path: "/sites/", component: Sites, name: "site-list" },
  { path: "/sites/new/", component: NewSite, name: "site-new" },
  {
    path: "/sites/:siteId/update/",
    component: UpdateSite,
    name: "site-update",
  },
  { path: "*", component: NotFound, name: "404" },
];

export const router = new VueRouter({
  routes, // short for `routes: routes`
  linkActiveClass: "active",
});

export const store = new Vuex.Store({
  modules: {
    formbuilder,
  },
  state: {
    user: {
      loaded: false,
      first_name: "",
      last_name: "",
      email: "",
      phone: "",
      license_number: "",
      district_user: false,
      admin_districts: [],
      profile: {
        municipality_inspections: false,
        company: {
          name: "",
          address: "",
          phone: "",
          license_number: "",
          available_districts: [],
          signature: "",
          approved: true,
          logo: null,
          payjunction_username: "",
          payjunction_password: "",
        },
      },
    },
    message: {
      variant: "info",
      showfor: 0,
      content: "",
    },
    inspection: null,
  },
  actions: {
    updateUser({ commit }, data) {
      HTTP.patch("api/accounts/user/", data)
        .then((response) => {
          commit("setUserData", response.data);
        })
        .catch((e) => {
          commit("setMessage", { message: e });
        });
    },
    fetchUserData({ commit }) {
      HTTP.get("api/accounts/user/")
        .then((response) => {
          if (response.data.error === "not_authenticated") {
            window.location.replace(
              process.env.VUE_APP_API_ROOT + "/accounts/login/",
            );
          } else if (response.data.error === "setup_incomplete") {
            window.location.replace(
              process.env.VUE_APP_API_ROOT + "/accounts/setup/profile/",
            );
          } else {
            commit("setUserData", response.data);
          }
        })
        .catch((e) => {
          commit("setMessage", { message: e });
        });
    },
    fetchInspection({ commit }, inspectionId) {
      return new Promise((resolve, reject) => {
        HTTP.get("/api/inspections/" + inspectionId + "/")
          .then((response) => {
            commit("SET_INSPECTION", response.data);
            resolve();
          })
          .catch((e) => {
            if (e.status === 402) {
              commit("CLEAR_INSPECTION");
              router.push({ name: "long-term-plan" });
              reject();
            } else {
              commit("setMessage", { message: e });
              reject();
            }
          });
      });
    },
    fetchInspectionWithSig({ commit }, p) {
      return new Promise((resolve, reject) => {
        HTTP.get(
          "/api/inspections/" +
            p.inspectionId +
            "/shared/?signature=" +
            p.signature,
        )
          .then((response) => {
            commit("SET_INSPECTION", response.data);
            resolve();
          })
          .catch((e) => {
            commit("setMessage", e);
            reject();
          });
      });
    },
    updateInspection({ commit, state }, payload) {
      return new Promise((resolve, reject) => {
        HTTP.put("/api/inspections/" + state.inspection.id + "/", payload)
          .then((response) => {
            commit("SET_INSPECTION", response.data);
            resolve();
          })
          .catch((e) => {
            commit("setMessage", e);
            reject();
          });
      });
    },
    createInspection({ commit }, payload) {
      return new Promise((resolve, reject) => {
        HTTP.post("/api/inspections/", payload)
          .then((response) => {
            commit("SET_INSPECTION", response.data);
            resolve();
          })
          .catch((e) => {
            commit("setMessage", e);
            reject();
          });
      });
    },
    setWaypoints({ commit, state }, payload) {
      return new Promise((resolve, reject) => {
        HTTP.post(
          "/api/inspections/" + state.inspection.id + "/waypoints/",
          payload,
        )
          .then((response) => {
            commit("SET_WAYPOINTS", response.data);
            resolve();
          })
          .catch((e) => {
            commit("setMessage", { message: e.data });
            reject(e);
          });
      });
    },
    clearInspection({ commit }) {
      commit("CLEAR_INSPECTION");
    },
    resetMessage({ commit }) {
      commit("setMessage", { content: "", variant: "info", timeout: -1 });
    },
  },
  mutations: {
    setMessage(state, payload) {
      state.message = {
        content: payload.message,
        variant: payload.variant ? payload.variant : "info",
        showfor: payload.timeout ? payload.timeout : 5,
      };
    },
    setUserData(state, payload) {
      state.user.first_name = payload.first_name;
      state.user.last_name = payload.last_name;
      state.user.email = payload.email;
      state.user.district_user = payload.is_city_user;
      state.user.admin_districts = payload.admin_districts;
      state.user.profile.phone = payload.profile.phone;
      state.user.profile.license_number = payload.profile.license_number;
      state.user.profile.company.name = payload.profile.company.name;
      state.user.profile.company.address = payload.profile.company.address;
      state.user.profile.company.phone = payload.profile.company.phone;
      state.user.profile.company.license_number =
        payload.profile.company.license_number;
      state.user.profile.company.available_districts =
        payload.profile.company.available_districts;
      state.user.profile.company.approved = payload.profile.company.approved;
      state.user.profile.company.logo = payload.profile.company.logo;
      state.user.profile.company.payjunction_username =
        payload.profile.company.payjunction_username;
      state.user.profile.company.payjunction_password =
        payload.profile.company.payjunction_password;
      state.user.profile.signature = payload.profile.signature;
      state.user.profile.setup_complete = payload.profile.setup_complete;
      state.user.profile.municipality_inspections =
        payload.profile.municipality_inspections;
      state.user.loaded = true;
    },
    SET_INSPECTION(state, inspection) {
      state.inspection = inspection;
    },
    CLEAR_INSPECTION(state) {
      state.inspection = null;
    },
    SET_WAYPOINTS(state, waypoints) {
      state.inspection.waypoints = waypoints;
    },
  },
  getters: {
    user: (state) => {
      return state.user;
    },
    fullName: (state) => {
      if (state.user.first_name || state.user.last_name) {
        return state.user.first_name + " " + state.user.last_name;
      } else {
        return "";
      }
    },
    company: (state) => {
      return state.user.profile.company;
    },
    phone: (state) => {
      return state.user.profile.phone;
    },
    licenseNumber: (state) => {
      return state.user.profile.company.license_number;
    },
    districtUser: (state) => {
      return state.user.district_user;
    },
    inspectorUser: (state) => {
      console.log(state.user.district_user);
      return (
        !state.user.district_user &&
        state.user.profile.company.available_districts.length > 1
      );
    },
    message: (state) => {
      return state.message;
    },
    inspection: (state) => {
      return state.inspection;
    },
  },
});

Vue.filter("formatDate", function (value) {
  return formatDate(value);
});

Vue.filter("formatTime", function (value) {
  return moment(value, "hh:mm:ss").format("h:mm a");
});

Vue.filter("formatDateTime", function (value) {
  return moment(value).format("M/D/YYYY h:mm a");
});

Vue.filter("constantToHuman", (value) => {
  return value
    .toLowerCase()
    .split("_")
    .map((w) => {
      return w.charAt(0).toUpperCase() + w.slice(1);
    })
    .join(" ");
});

Vue.filter("formatCurrency", (value) => {
  var formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });
  return formatter.format(value);
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
