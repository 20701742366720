<template>
  <div class="check-wrapper">
    <span class="order-sm-1 order-2 radio-control">
      <b-form-checkbox
        :id="'input-' + eleId"
        v-model="value"
        :required="question.required"
        @change="sendAnswer"
        class="check-control"
      ></b-form-checkbox>
    </span>
    <span class="order-sm-2 order-1 mr-5 ml-1">
      <label>
        {{ question.label }}
      </label>
    </span>
  </div>
</template>
<script>
export default {
  name: "FbCheckbox",
  props: {
    question: Object,
    initial: {
      type: Boolean,
      default: null,
    },
  },
  data() {
    return {
      value: this.initial === null ? this.question.value : this.initial,
    };
  },
  computed: {
    eleId() {
      return `question-${this.question.id}`;
    },
    booleanValue() {
      if (this.value == "") {
        return false;
      } else {
        return this.value;
      }
    },
  },
  methods: {
    sendAnswer() {
      this.$emit("answered", {
        answer: this.booleanValue,
        question: this.question.id,
      });
    },
  },
  mounted() {
    this.sendAnswer();
  },
};
</script>
<style>
.check-wrapper {
  display: flex;
}
.check-control {
  flex-shrink: 0;
}
</style>
