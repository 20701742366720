<template>
  <div>
    <div v-if="!inspection">
      <Spinner></Spinner>
    </div>
    <div v-else>
      <div class="row">
        <b-alert v-model="hasError" variant="danger">
          <p>
            There were errors with your submission. Please correct them below:
          </p>
          <ul>
            <li v-for="(error, field) in errors" v-bind:key="field">
              {{ field }}:
              <span v-for="e in error" v-bind:key="e">{{ e }}</span>
            </li>
          </ul>
        </b-alert>
      </div>
      <div class="row justify-content-md-center">
        <div class="col-md-6">
          <p>
            <strong>Lateral Video Upload</strong><br />
            <span
              class="text-right text-muted help-cursor"
              v-b-tooltip.hover
              title="When uploading multiple videos, please order individual files so video is linear, from the property to the street. Select all video files to upload at the same time and then select the Upload button. Videos will be joined together."
            >
              <small
                >If two or more videos are uploaded, they will be joined. <br />
                How to order?</small
              >
            </span>
          </p>
          <div v-if="inspection.last_video" class="current-image">
            <video class="embed-responsive-item" controls>
              <source :src="inspection.last_video.signed_url" />
            </video>
            <div class="overlay video-overlay" @click="uploadReset('video')">
              Remove Media
            </div>
          </div>
          <div v-else>
            <media-upload
              :inspection="inspection"
              media-type="VIDEO"
              v-on:upload-complete="uploadComplete"
              v-on:upload-reset="uploadReset"
            >
            </media-upload>
          </div>
          <p class="mt-3">
            <strong>Video taking too long to upload?</strong>
            Try reducing the size or quality of the video file before uploading
            it to Forward Lateral. For example, If you are using a Rigid camera
            system, you can lower the video quality to "Medium" or "Low" to
            reduce video upload time. Video quality is automatically compressed
            to these settings once uploaded.
          </p>
        </div>
        <div class="col">
          <p><strong>Inspection and Suggested Repairs Summary</strong><br /></p>
          <b-form @submit="saveForm">
            <b-form-group id="summary" label-for="summary">
              <b-textarea
                v-model="inspection.summary"
                rows="16"
                required
              ></b-textarea>
            </b-form-group>
            <b-form-group
              v-if="pooinspection"
              id="next-steps"
              label="Please provide the next steps that your client should take after reviewing this inspection."
            >
              <b-textarea
                v-model="inspection.next_steps"
                rows="16"
                required
              ></b-textarea>
            </b-form-group>
            <div class="text-right">
              <b-button
                id="continue"
                :disabled="!allUploaded"
                variant="primary"
                type="submit"
                class="mt-2"
              >
                Save and Continue
              </b-button>
            </div>
          </b-form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Spinner from "../util/Spinner";
import { HTTP } from "../../http";
import { router } from "../../main";
import MediaUpload from "../util/MediaUpload";
import { mapGetters } from "vuex";
export default {
  name: "InspectionMedia",
  components: {
    MediaUpload,
    Spinner,
  },
  data: function () {
    return {
      uploaded: {
        video: false,
      },
      errors: null,
    };
  },
  methods: {
    uploadComplete: function (result) {
      this.uploaded.video = false;
      this.inspection.last_video = null;
      for (var i = 0; i < result.result.results.length; i++) {
        const r = result.result.results[i];
        if (r.stepName === "joined") {
          const payload = {
            inspection: this.inspectionId,
            media_type: result.mediaType,
            mime_type: r.mime,
            url: r.ssl_url,
          };
          HTTP.post("/api/media/", payload)
            .then((response) => {
              if (response.data.signed_url) {
                this.uploaded.video = true;
              }
            })
            .catch((e) => {
              this.errors = e.data;
            });
        }
      }
    },
    uploadReset: function () {
      this.uploaded.video = false;
      this.inspection.last_video = null;
    },
    saveForm: function (event) {
      event.preventDefault();
      this.$store.dispatch("updateInspection", this.inspection).then(() => {
        router.push({
          name: "inspection-eval",
          params: { inspectionId: this.inspection.id },
        });
      });
    },
  },
  computed: {
    inspectionId: function () {
      return this.$route.params.inspectionId;
    },
    allUploaded: function () {
      return Boolean(this.uploaded.video);
    },
    hasError: function () {
      return this.errors != null;
    },
    pooinspection: function () {
      return this.inspection.type === "POO";
    },
    ...mapGetters(["inspection"]),
  },
  mounted: function () {
    this.$store.dispatch("fetchInspection", this.inspectionId).then(() => {
      if (this.inspection.last_video) {
        this.uploaded.video = true;
      }
    });
  },
};
</script>
<style>
.current-image {
  position: relative;
}
.overlay {
  position: absolute;
  bottom: 0;
  background: rgb(0, 0, 0);
  background: rgba(0, 0, 0, 0.5); /* Black see-through */
  color: #f1f1f1;
  width: 100%;
  transition: 0.5s ease;
  opacity: 1;
  color: white;
  font-size: 20px;
  padding: 20px;
  text-align: center;
  cursor: pointer;
}
.video-overlay {
  position: relative;
}
</style>
