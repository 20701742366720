<template>
  <div>
    <div v-if="!inspection">
      <Spinner></Spinner>
    </div>
    <div v-else>
      <div class="jumbotron">
        <p class="lead">
          Your inspection is almost complete! Please review the information
          below and make any needed changes to your inspection before
          submitting.
        </p>
        <p>
          <strong>Municipality: {{ buttonText }}</strong>
        </p>
        <p v-if="inspection.site_info.project_name">
          <strong>Project: {{ inspection.site_info.project_name }}</strong>
        </p>
        <p>
          Please note that the information in your inspection cannot be changed
          once it is submitted.
        </p>
        <p>
          Use "Save and return to inspections" to save your progress and submit
          at a later time. You may also want to print a copy for your records.
        </p>

        <p v-if="!inspection.reinspection">
          This inspection will cost ${{ inspection.cost_to_submit / 100 }} to
          submit.
        </p>
        <b-form @submit="saveForm" ref="defects-form">
          <b-button
            type="submit"
            variant="success"
            @click="submitToCity = true"
            class="mt-2 mt-md-0"
          >
            Save and submit to {{ buttonText }}
          </b-button>
          &nbsp;
          <b-button
            type="submit"
            variant="primary"
            @click="submitToCity = false"
            >Save and return to dashboard</b-button
          >
        </b-form>
      </div>
      <InspectionDetail :displayreview="false"></InspectionDetail>
    </div>
  </div>
</template>
<script>
import { HTTP } from "../../http";
import Spinner from "../util/Spinner";
import InspectionDetail from "./InspectionDetail";
import { mapGetters } from "vuex";
export default {
  name: "SubmitToCity",
  components: { Spinner, InspectionDetail },
  data: function () {
    return {
      submitToCity: false,
      errors: null,
    };
  },
  methods: {
    saveForm: function (event) {
      event.preventDefault();
      if (!this.submitToCity) {
        this.$router.push({ name: "home" });
      } else {
        let proceedText = "";
        if (this.inspection.reinspection) {
          proceedText = `Submit this inspection? Once submitted, the inspection cannot be modified.`;
        } else {
          proceedText = `Submit this inspection? This will charge $${
            this.inspection.cost_to_submit / 100
          } to your credit card. Once submitted, the inspection cannot be modified.`;
        }
        const goToCity = confirm(proceedText);
        if (goToCity) {
          HTTP.post(
            "/api/inspections/" + this.inspection.id + "/update_status/"
          )
            .then(() => {
              this.$router.push({ name: "home" });
            })
            .catch((error) => {
              for (let e of error.data.errors) {
                let message = "";
                if (error.data.payment_err) {
                  message = `There was a problem processing paymet for this inspection. Please update your account
                  payment settings and try again. The error was: ${e}`;
                } else {
                  message =
                    "There was an error with submitting this inspection: " + e;
                }
                this.$store.commit("setMessage", {
                  message: message,
                  variant: "danger",
                  timeout: 60 * 5,
                });
              }
              this.errors = error.data.errors;
            });
        }
      }
    },
  },
  computed: {
    inspectionId: function () {
      return this.$route.params.inspectionId;
    },
    hasError: function () {
      return this.errors != null;
    },
    pooinspection: function () {
      return this.inspection.type === "POO";
    },
    buttonText: function () {
      if (this.pooinspection) {
        return "client";
      } else {
        return this.inspection.site_info.district_info.name;
      }
    },
    ...mapGetters(["inspection"]),
  },
  mounted: function () {
    this.$store.dispatch("fetchInspection", this.inspectionId);
  },
};
</script>
