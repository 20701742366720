export function utcDateString(time){
  function pad(val, len) {
    val = String(val);
    len = len || 2;
    while (val.length < len) val = "0" + val;
    return val;
  }

  var now = new Date();
  now.setTime(time);

  var utc = new Date(
    Date.UTC(
      now.getFullYear(),
      now.getMonth(),
      now.getDate(),
      now.getHours(),
      now.getMinutes(),
      now.getSeconds()
    )
  );

  var cDate  = utc.getDate();
  var cMonth = utc.getMonth();
  var cYear  = utc.getFullYear();
  var cHour  = utc.getHours();
  var cMin   = utc.getMinutes();
  var cSec   = utc.getSeconds();

  var result = cYear + '/' + pad((cMonth + 1)) + '/' + pad(cDate);
  result += ' ' + pad(cHour) + ':' + pad(cMin) + ':' + pad(cSec) + '+00:00';

  return result
}

export function formatDate(datestring){
  const parts = datestring.split('-')
  const mydate = new Date(parts[0], parts[1] - 1, parts[2]);
  return mydate.getMonth() + 1 + '/' + mydate.getDate() + '/' + mydate.getFullYear()
}
