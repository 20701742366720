<template>
  <b-form-radio-group
    :id="'input-' + eleId"
    v-model="value"
    :required="question.required"
    class="d-block"
    @change="sendAnswer"
  >
    <div class="radio-wrapper">
      <span class="order-sm-1 order-2 radio-control">
        <b-form-radio :name="'name-' + eleId" :value="true">Yes</b-form-radio>
        <b-form-radio :name="'name-' + eleId" :value="false">No</b-form-radio>
      </span>
      <span class="order-sm-2 order-1 mr-5">
        <label>
          {{ question.label }}
        </label>
      </span>
    </div>
  </b-form-radio-group>
</template>
<script>
export default {
  name: "FbCheckbox",
  props: {
    question: Object,
    initial: {
      type: Boolean,
      default: null,
    },
  },
  data() {
    return {
      value: this.initial === null ? this.question.value : this.initial,
    };
  },
  computed: {
    eleId() {
      return `question-${this.question.id}`;
    },
  },
  methods: {
    sendAnswer() {
      this.$emit("answered", {
        answer: this.value,
        question: this.question.id,
      });
    },
  },
  mounted() {
    this.sendAnswer();
  },
};
</script>
<style>
.radio-wrapper {
  display: flex;
}
.radio-control {
  flex-shrink: 0;
}
</style>
