<template>
  <div class="row">
    <div :class="readonly ? 'col-md-12' : 'col-md-8'" class="text-right">
      <gmap-map
        :center="center"
        :zoom="20"
        mapTypeId="satellite"
        style="width:100%;  height: 400px;"
      >
        <gmap-marker
          v-for="marker in markers"
          v-bind:key="marker.id"
          :position="marker.position"
          :draggable="!readonly"
          :label="marker.label.text"
          @dragend="dragEnd($event, marker)"
        ></gmap-marker>

        <gmap-polyline
          v-for="(linestring, idx) in linestrings"
          v-bind:key="`ls-${idx}`"
          :path="linestring"
          :options="polylineoptions"
        ></gmap-polyline>
      </gmap-map>
      <p class="disclaimer" v-if="readonly">
        Lateral locations will be re-verified by a licensed contractor before any rehabilitation
        costs or scheduled work occurs.
      </p>
      <Spinner :small="true" variant="dark" v-show="saving"></Spinner>
      <b-button variant="outline-success" @click.prevent="addpoint" class="mt-1" v-if="!readonly">
        <font-awesome-icon icon="map-marker-alt"/> Add a lateral waypoint
      </b-button>
      <b-button variant="outline-success" @click.prevent="addSegment" class="ml-1 mt-1" v-if="!readonly">
        <font-awesome-icon icon="grip-lines"/> Add a new lateral segment
      </b-button>
      <b-button variant="outline-danger" @click.prevent="removepoint" class="ml-1 mt-1" v-if="!readonly" :disabled="!canRemoveMarker">
        <font-awesome-icon icon="trash"/> Remove a lateral waypoint
      </b-button>
    </div>
    <div class="col">
      <p>Waypoints<br/>
      <span class="text-right text-muted help-cursor" v-b-tooltip.hover
            title="Create and label waypoints below. Mark observations in the pipe later on step 5. Mark Observations.">
            <small>How to use.</small>
      </span>
      </p>
      <div v-for="(segment, idx) in segments" :key="idx">
        <div v-for="(waypoint, widx) in segment" :key="waypoint.position">

          <b-input-group class="mb-1" :prepend="'' + (waypoint.position + 1)">
            <b-form-input v-model="waypoint.description" debounce="500" :readonly="readonly"></b-form-input>
            <b-input-group-append v-if="!readonly">
              <b-button variant="primary" @click="toggleOpenWaypointDetails(idx, widx)">+</b-button>
            </b-input-group-append>
          </b-input-group>

          <div v-show="(idx === segmentExpand && waypointExpand == widx) || inspection.manualcoords || (waypoint.measured && readonly)">
            <b-input-group class="mb-1 ml-2" prepend="Lat" size="sm">
              <b-form-input type="number" step="any" v-model="waypoint.lat" debounce="500" :readonly="readonly" @input="manualCoords"></b-form-input>
            </b-input-group>
            <b-input-group class="mb-1 ml-2" prepend="Lng" size="sm">
              <b-form-input  type="number" step="any" v-model="waypoint.lng" debounce="500" :readonly="readonly" @input="manualCoords"></b-form-input>
            </b-input-group>
            <b-input-group class="mb-1 ml-2" prepend="Depth" size="sm">
              <b-form-input  type="number" step="any" v-model="waypoint.depth" debounce="500" :readonly="readonly" @input="manualCoords"></b-form-input>
            </b-input-group>
            <b-form-checkbox
              v-model="waypoint.measured"
              class="m-2"
              :disabled="readonly"
            >
              I certify this lat/lng data was obtained with the use of accurate survey equipment.
            </b-form-checkbox>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Spinner from '@/components/util/Spinner'
export default {
  name: 'WaypointMap',
  props: ['center', 'inspection', 'readonly'],
  components: {Spinner},
  data: function(){
    return {
      segments: [],
      segmentExpand: null,
      waypointExpand: null,
      saving: false,
      polylineoptions: {
        strokeColor: '#ffff00'
      }
    }
  },
  mounted: function(){
    let waypoints = this.inspection.waypoints
    if(waypoints.length < 1){
      this.segments.push([
        { lat: this.center.lat, lng: this.center.lng - 0.0001, description: 'Lateral Start', segment: 0, position: 0},
        { lat: this.center.lat, lng: this.center.lng, description: 'Cleanout', segment: 0, position: 1},
        { lat: this.center.lat, lng: this.center.lng + 0.0001, description: 'Lateral End', segment: 0, position: 2},
      ])
    }else{
      waypoints.sort((a, b) => {
        return a.position > b.position ? 1 : -1
      })
      for (const waypoint of waypoints){
        while(this.segments.length -1 < waypoint.segment){
          this.segments.push([])
        }
        this.segments[waypoint.segment].push(waypoint)
      }
    }
  },
  computed: {
    markers(){
      let markers = []
      for(const segment of this.segments){
        for(const waypoint of segment){
          markers.push({
            id: waypoint.position,
            position: { lat: parseFloat(waypoint.lat), lng: parseFloat(waypoint.lng) },
            label: {text: '' + (waypoint.position + 1)},
            segment: waypoint.segment
          })
        }
      }
      return markers
    },
    linestrings(){
      return this.segments.map(segment =>{
        return segment.map(waypoint => {
          return {lat: parseFloat(waypoint.lat), lng: parseFloat(waypoint.lng)}
        })
      })
    },
    canRemoveMarker(){
      return this.markers.length > 2
    }
  },
  watch: {
    segments: {
      handler: function(){
        this.saveWaypoints()
      },
      deep: true
    }
  },
  methods: {
    ranId(){
      return Math.trunc(1+Math.random() * 10000)
    },
    dragEnd(event, marker){
      const waypoint_idx = this.segments[marker.segment].findIndex(waypoint => waypoint.position === marker.id)
      this.segments[marker.segment][waypoint_idx].lat = event.latLng.lat().toFixed(9)
      this.segments[marker.segment][waypoint_idx].lng = event.latLng.lng().toFixed(9)
      this.$emit('linechanged', this.segments)
    },
    addpoint(){
      const segmentIdx = this.segments.length - 1

      const newPosition = {
        lat: (parseFloat(this.segments[segmentIdx][0].lat) + parseFloat(this.segments[segmentIdx][1].lat)) / 2,
        lng: (parseFloat(this.segments[segmentIdx][0].lng) + parseFloat(this.segments[segmentIdx][1].lng)) / 2,
      }
      this.segments[segmentIdx].splice(1, 0, {
        lat: newPosition.lat,
        lng: newPosition.lng,
        description: 'Joint',
        segment: segmentIdx,
        position: this.segments[segmentIdx].length
      })
      this.reordermakers()
    },
    addSegment() {
      const newSegment = this.segments.length
      const newPosition = this.markers[this.markers.length - 1].id + 1
      this.segments.push([
        { lat: this.center.lat -0.0001, lng: this.center.lng - 0.0001,
          description: 'Lateral Start', segment: newSegment, position: newPosition
        },
        { lat: this.center.lat -0.0001, lng: this.center.lng + 0.0001,
          description: 'Lateral End', segment: newSegment, position: newPosition + 1
        },
      ])
    },
    removepoint(){
      if(this.canRemoveMarker){
        const segmentIdx = this.segments.length - 1
        if(this.segments[segmentIdx].length < 3){
          this.segments.splice(segmentIdx, 1)
        }else{
          this.segments[segmentIdx].splice(1, 1)
          this.reordermakers()
        }
      }
    },
    reordermakers(){
      let count = 0;
      for (var i = 0; i < this.segments.length; i++) {
        for (var j = 0; j < this.segments[i].length; j++) {
          this.segments[i][j].position = count
          count ++
        }
      }
    },
    saveWaypoints(){
      if(!this.readonly){
        this.saving = true
        this.$store.dispatch('setWaypoints', this.segments.flat().filter(seg => seg.description)).then(() => {
          this.saving = false
        }).catch(e =>{
          this.$store.commit('setMessage', {message: e.data, variant: 'danger'})
        })
      }
    },
    toggleOpenWaypointDetails(segidx, wpidx){
      this.segmentExpand = segidx
      this.waypointExpand = this.waypointExpand === wpidx ? null : wpidx
    },
    manualCoords() {
      this.$emit('manualcoords')
    }
  }
}
</script>
<style>
.disclaimer {
  font-size: 10px;
  font-style: italic;
  text-align: left;
}
</style>
