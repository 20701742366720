import { HTTP } from '@/http.js'

export const formbuilder = {
  state: () => ({
    questions: [],
    selectedQuestion: null,
    questionSets: [],
    selectedQuestionSet: null,
  }),
  mutations: {
    setQuestions (state, questions) {
      state.questions = questions
    },
    setSelectedQuestion(state, question) {
      state.selectedQuestion = question
    },
    addQuestion (state, question) {
      state.questions.unshift(question)
    },
    setQuestionSets (state, questionSets) {
      state.questionSets = questionSets
    },
    setSelectedQuestionSet (state, questionSet) {
      state.selectedQuestionSet = questionSet
    },
    addQuestionSet (state, questionSet) {
      state.questionSets.unshift(questionSet)
    }
  },

  actions: {
    fetchQuestions (context){
      HTTP.get('/api/formbuilder/questions/').then(response => {
        context.commit('setQuestions', response.data)
      })
    },

    updateQuestion(context, question) {
      HTTP.put(`/api/formbuilder/questions/${question.id}/`, question).then(response => {
        const updatedQuestion = response.data
        const newQuestions = context.state.questions.map(q => {
          return updatedQuestion.id === q.id ? updatedQuestion : q
        })
        context.commit('setQuestions', newQuestions)
        context.commit('setSelectedQuestion', updatedQuestion)
        context.commit('setMessage', {message: 'Question Updated.'})
      }).catch(e => {
        context.commit('setMessage', e)
      })
    },

    createQuestion(context, question) {
      HTTP.post('/api/formbuilder/questions/', question).then(response => {
        const newQuestion = response.data
        context.commit('addQuestion', newQuestion)
        context.commit('setMessage', { message: 'Question added.' })
      }).catch(e => {
        context.commit('setMesssage', e)
      })
    },

    fetchQuestionSets (context) {
      HTTP.get('/api/formbuilder/questionsets/').then(response => {
        context.commit('setQuestionSets', response.data)
      })
    },

    updateQuestionSet (context, questionSet) {
      HTTP.put(`/api/formbuilder/questionsets/${questionSet.id}/`, questionSet).then(response => {
        const updatedQuestionSet = response.data
        const newQuestionSets = context.state.questionSets.map(qs => {
          return updatedQuestionSet.id === qs.id ? updatedQuestionSet : qs
        })
        context.commit('setQuestionSets', newQuestionSets)
        context.commit('setSelectedQuestionSet', updatedQuestionSet)
        context.commit('setMessage', { message: 'Question Set Updated' })
      }).catch(e => {
        context.commit('setMessage', e)
      })
    },

    copyQuestionSet (context, questionSet) {
      const copiedQuestionSet = { ...questionSet, name: `${questionSet.name} Copy` }
      return context.dispatch('createQuestionSet', copiedQuestionSet)
    },

    createQuestionSet(context, questionSet) {
      HTTP.post(`/api/formbuilder/questionsets/`, questionSet).then(response => {
        const newQuestionSet = response.data
        context.commit('addQuestionSet', newQuestionSet)
        context.commit('setSelectedQuestionSet', newQuestionSet)
        context.commit('setMessage', { message: 'Question Set Created' })
      }).catch(e => {
        context.commit('setMessage', e)
      })
    }
  },
  getters: {
    getQuestionById: (state) => (id) => {
      return state.questions.find(question => question.id === id)
    }
  }
}
