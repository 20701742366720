<template>
  <b-breadcrumb class="mt-2">
    <b-breadcrumb-item :to="item.route" v-for="item in items" :key="item.text" :active="item.active" :disabled="item.disabled">
      {{ item.index }}. {{ item.text }}
    </b-breadcrumb-item>
  </b-breadcrumb>
</template>
<script>
  import {mapGetters} from 'vuex'
  export default {
    name: 'Breadcrumb',
    data: function(){
      return {}
    },
    computed: {
        items: function(){
          var siteRoute = {
            name: 'site-new',
            query: {type: this.$route.query.type || 'district'}
          }
          if(this.inspection){
            siteRoute = {
              name: 'site-update',
              params: {siteId: this.inspection.site},
              query: {inspectionId: this.inspection.id, type: this.inspection.type === 'POO' ? 'po' : 'district'}
            }
          }else{
            if(this.$route.params.siteId){
              siteRoute = {
                name: 'site-update',
                params: {siteId: this.$route.params.siteId}, query: { type: this.$route.query.type || 'DISTRICT'}
              }
            }
          }
          return {
            siteNew: {
              route: siteRoute,
              text: 'Property Information',
              index: 1,
              active: this.$route.name === 'site-update',
              disabled: !this.enabled.includes('siteNew'),
            },
            inspectionNew: {
              route: {name: 'inspection-update', params: {inspectionId: this.inspection? this.inspection.id: null}},
              text: 'Inspection Form',
              index: 2,
              active: this.$route.name === 'inspection-update',
              disabled: !this.enabled.includes('inspectionNew'),
            },
            inspectionProperty: {
              route: {name: 'inspection-property', params: {inspectionId: this.inspection ? this.inspection.id : null}},
              text: 'Photo & PDF',
              index: 3,
              active: this.$route.name === 'inspection-property',
              disabled: !this.enabled.includes('inspectionProperty'),
            },
            inspectionMedia: {
              route: {name: 'inspection-media', params: {inspectionId: this.inspection ? this.inspection.id : null}},
              text: 'Upload Video',
              index: 4,
              active: this.$route.name === 'inspection-media',
              disabled: !this.enabled.includes('inspectionMedia'),
            },
            inspectionEval: {
              route: {name: 'inspection-eval', params: {inspectionId: this.inspection ? this.inspection.id : null}},
              text: 'Mark Observations',
              index: 5,
              active: this.$route.name === 'inspection-eval',
              disabled: !this.enabled.includes('inspectionEval'),
            },
            submitToCity: {
              route: {name: 'submit-to-city', params: {inspectionId: this.inspection ? this.inspection.id : null}},
              text: this.inspection ? `Submit ($${this.inspection.cost_to_submit / 100})` : 'Submit',
              index: 6,
              active: this.$route.name === 'submit-to-city',
              disabled: !this.enabled.includes('submitToCity'),
            }
          }
        },
        enabled: function(){
          let links = ['siteNew']
          if(this.inspection){
            links.push('inspectionNew')
            if(this.inspection.questions_answered){
              links.push('inspectionProperty', 'inspectionMedia')

              if(this.inspection.last_video){
                links.push('inspectionEval', 'submitToCity')
              }
            }
          }
          return links
        },
        leader(){
          if(this.$route.query.type){
            return this.$route.query.type === 'district' ? 'Muncipality' : 'Client Only'
          }else if(this.inspection){
            return this.inspection.type === 'DISTRICT' ? 'Municipality' : 'Client Only'
          }else{
            return ''
          }
        },
        ...mapGetters(['inspection'])
      }
    }
</script>
<style>
a.disabled {
  /* Make the disabled links grayish*/
  color: gray;
  /* And disable the pointer events */
  pointer-events: none;
}
li.active {
  font-weight: 800
}

.breadcrumb {
    display: block;
    text-align: left;
}
.breadcrumb-item + .breadcrumb-item::before {
  content: none;
  padding-right: 0rem;
}
.breadcrumb-item + .breadcrumb-item {
  padding-left: 0rem;
}

@media (min-width: 768px) {
  .breadcrumb {
    display: flex;
    text-align: center;
  }
  .breadcrumb-item + .breadcrumb-item::before {
    content: ">";
    padding-right: 0.5rem;
  }
  .breadcrumb-item + .breadcrumb-item {
    padding-left: 0.5rem;
  }
}
</style>
