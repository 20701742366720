<template>
  <div class="row">
    <div class="questions p-2">
      <b-input v-model="searchstr" placeholder="Search" size="sm"></b-input>
      <b-list-group>
        <span v-for="question in filteredQuestions" :key="question.id" @click="selectQuestion(question)">
          <div class="draggable" draggable @dragstart="startDrag($event, question)">
            <question-detail :question="question" :selected="selectedQuestion === question"></question-detail>
          </div>
        </span>
      </b-list-group>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import QuestionDetail from './QuestionDetail.vue'
export default {
  name: 'QuestionList',
  components: {
    QuestionDetail
  },
  data(){
    return {
      searchstr: ''
    }
  },
  mounted(){
    this.$store.dispatch('fetchQuestions')
  },
  methods:{
    selectQuestion(question) {
      this.$store.commit('setSelectedQuestion', question)
    },
    startDrag(event, question) {
      event.dataTransfer.dropEffect = 'move'
      event.dataTransfer.effectAllowed = 'move'
      event.dataTransfer.setData('question', question.id)
    }
  },
  computed: {
    ...mapState({
      questions: state => state.formbuilder.questions,
      selectedQuestion: state => state.formbuilder.selectedQuestion
    }),
    searchLower(){
      return this.searchstr.toLowerCase()
    },
    filteredQuestions(){
      if(!this.searchstr){
        return this.questions
      }
      return this.questions.filter(q => q.label.toLowerCase().includes(this.searchLower) || q.name.toLowerCase().includes(this.searchLower))
    }
  }
}
</script>
<style>
  .questions {
    overflow-y: scroll;
    background: darkgray;
  }
</style>
