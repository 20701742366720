<template>
  <b-tabs content-class="mt-3">
    <b-tab title="Personal Information">
      <ProfileForm></ProfileForm>
    </b-tab>
    <b-tab title="Company Information">
      <CompanyForm></CompanyForm>
    </b-tab>
    <b-tab title="Payment Method">
      <Payment></Payment>
    </b-tab>
  </b-tabs>
</template>
<script>
  import ProfileForm from './ProfileForm.vue'
  import CompanyForm from './CompanyForm.vue'
  import Payment from './Payment.vue'
  export default {
    name: 'Account',
    components: {ProfileForm, CompanyForm, Payment},
    data: function(){
      return {}
    }
  }
</script>
