<template>
  <div>
    <b-alert v-model="hasError" variant="danger">
      <p>There were errors with your submission. Please correct them below:</p>
      <ul>
        <li v-for="(error, field) in errors" v-bind:key="field">
          {{ field }}: <span v-for="e in error" v-bind:key="e">{{ e }}</span>
        </li>
      </ul>
    </b-alert>
    <b-form @submit="onSubmit">
      <div class="framed">
        <div class="form-row">
          <b-form-group
            id="input-group-date"
            class="col-sm"
            label="Date of Inspection"
            label-for="input-date"
          >
            <b-form-input
              id="input-date"
              v-model="form.date"
              type="date"
              required
            ></b-form-input>
          </b-form-group>

          <b-form-group
            id="input-group-time"
            class="col-sm"
            label="Time of Inspection"
            label-for="input-time"
          >
            <b-form-input
              id="input-time"
              v-model="form.time"
              type="time"
              step="60"
              required
            ></b-form-input>
          </b-form-group>
        </div>
        <AnswerableSet
          :questionset="dynamicQuestions"
          :inspection="inspection"
          @answersupdated="answersupdated"
          :initial_answers="form.answer_set"
        >
        </AnswerableSet>

        <p>
          Please indicate the location of the sewer lateral. Click and drag the
          markers to change the beginning/end of the lateral.
        </p>
        <WaypointMap
          :center="map.center"
          :inspection="inspection"
          :readonly="false"
          @manualcoords="form.manualcoords = true"
        ></WaypointMap>

        <b-form-checkbox
          id="input-certification"
          name="certification"
          v-model="certified"
          :required="true"
          v-if="!pooinspection"
          class="m-2"
        >
          <strong>
            I have moved the map pins to indicate the location of the lateral to
            the best of my ability.
          </strong>
        </b-form-checkbox>

        <p v-if="pooinspection"><strong>Please sign below.</strong></p>
        <p v-else>
          <strong>
            I certify that the information and video recording I have provided
            with this form are true and correct. I declare under penalty of
            perjury that all information submitted here applies to the listed
            addresses only. The information submitted complies with all
            requirements set by the municipality I am submitting to.
          </strong>
        </p>
        <b-alert :show="needsSig" variant="danger"
          >You must sign to continue</b-alert
        >
        <div class="signature">
          <canvas
            ref="signaturePad"
            class="border rounded"
            width="400"
            height="200"
          ></canvas>
          <b-button @click="clearSignature" class="d-block" size="sm"
            >Clear Signature</b-button
          >
          <b-form-checkbox
            id="input-save-signature"
            name="save-signature"
            v-model="saveSignature"
          >
            Save this signature for future use.
          </b-form-checkbox>
        </div>
        <br />
        <div class="text-right">
          <b-button type="submit" variant="primary">Save and Continue</b-button>
        </div>
      </div>
    </b-form>
  </div>
</template>
<script>
import * as SignaturePad from "signature_pad/dist/signature_pad";
import WaypointMap from "../util/WaypointMap.vue";
import AnswerableSet from "@/components/formbuilder/AnswerableSet.vue";
import { mapGetters } from "vuex";
export default {
  name: "InspectionForm",
  components: { WaypointMap, AnswerableSet },
  props: {
    errors: {
      type: Object,
      default: function () {
        return {};
      },
    },
    site: {
      type: Object,
    },
    initial: {
      type: Object,
      default: function () {
        const now = new Date();
        return {
          type: "DISTRICT",
          date: now.toLocaleDateString("en-CA"),
          time: now.toTimeString().substring(0, 5),
          sewer_usage: null,
          pipe_size: null,
          pipe_material: "",
          pipe_material_2: "",
          pipe_material_other: "",
          camera_direction: null,
          total_length: null,
          reason_for_inspection: "REAL_ESTATE",
          lateral_id_sl: "",
          apn_num: "",
          cleanout_accessible: false,
          ejector_pump_present: false,
          cross_private_property: false,
          neighboring_property_address: "",
          city_sewer_right_way: false,
          more_than_one_structure: false,
          more_than_one_lateral: false,
          more_than_one_lateral_included: null,
          backwater_valve: false,
          backwater_valve_functional: null,
          backwater_valve_required: null,
          outside_drains: false,
          pass_pressure_test: null,
          outside_drains_verification: null,
          sewer_relief_valve: null,
          factory_wye_at_connection: false,
          signature_pad: null,
          linestring: "[]",
          manualcoords: false,
          answer_set: [],
          questions_answered: false,
        };
      },
    },
  },
  data: function () {
    return {
      form: { ...this.initial },
      signaturePad: null,
      saveSignature: true,
      needsSig: false,
      certified: false,
      map: {
        center: {
          lat: this.site.lat,
          lng: this.site.lng,
        },
      },
      pipe_materials: [
        { value: "CAST_IRON", text: "Cast Iron" },
        { value: "ORANGEBURG", text: "Orangeburg" },
        { value: "VITRIFIED_CLAY_PIPE", text: "(VCP) Vitrified Clay Pipe" },
        { value: "CURED_IN_PLACE_PIPE", text: "(CIPP) Cured in Place Pipe" },
        {
          value: "POLYVINYL_CHLORIDE_PIPE",
          text: "(PVC) Polyvinyl Chloride Pipe",
        },
        {
          value: "ACRYLONITRILE_BUTADIENE_STYRENE",
          text: "(ABS) Acrylonitrile Butadiene Styrene",
        },
        {
          value: "HIGH_DENSITY_POLYETHYLENE_PIPE",
          text: "(HDPE) High Density Polyethylene Pipe",
        },
        { value: "CONCRETE", text: "Concrete" },
        { value: "UNKNOWN", text: "Unknown" },
      ],
      inspection_reasons: [
        { value: "REAL_ESTATE", text: "Property sale" },
        { value: "SLIP", text: "Sewer Lateral Inspection Program" },
        { value: "CONSTRUCTION_PERMIT", text: "Building, construction or ADU" },
        { value: "CAPITAL_IMPROVEMENT", text: "Capital improvement project" },
        {
          value: "MALFUNCTION",
          text: "Sewage overflow, malfunction of Lateral",
        },
        { value: "OTHER_REASON", text: "Other reason, see notes" },
      ],
    };
  },
  methods: {
    onSubmit: function (event) {
      event.preventDefault();
      if (this.checkSignature()) {
        if (this.saveSignature) {
          this.updateSignature().then(() => {
            this.$emit("form-submitted", this.form);
          });
        } else {
          this.$emit("form-submitted", this.form);
        }
      }
    },
    setSignature: function () {
      this.form.signature_pad = this.signaturePad.toDataURL();
      this.needsSig = false;
    },
    clearSignature: function () {
      this.signaturePad.clear();
      this.form.signature_pad = null;
    },
    checkSignature: function () {
      if (this.signaturePad.isEmpty()) {
        this.needsSig = true;
        return false;
      } else {
        return true;
      }
    },
    updateSignature: function () {
      return this.$store.dispatch("updateUser", {
        profile: { signature: this.signaturePad.toDataURL() },
      });
    },
    linechanged: function (line) {
      this.form.linestring = JSON.stringify(line);
    },
    answersupdated(answers) {
      this.form.answer_set = answers;
    },
  },
  computed: {
    ...mapGetters({
      user: "user",
      inspection: "inspection",
    }),
    hasError: function () {
      return this.errors != null;
    },
    pooinspection: function () {
      return this.form.type === "POO" || this.$route.query.type === "po";
    },
    dynamicQuestions: function () {
      let placementCopy = [
        ...this.inspection.question_set.questionplacement_set,
      ];
      const placements = placementCopy.sort((a, b) => a.order - b.order);
      return placements.map((p) => p.question);
    },
  },
  watch: {
    "form.backwater_valve": function () {
      this.form.backwater_valve_functional = null;
      this.form.backwater_valve_required = null;
    },
    "form.outside_drains": function () {
      if (this.form.outside_drains && !this.form.outside_drains_verification) {
        this.form.outside_drains_verification = "CCTV";
      }
    },
    "form.more_than_one_lateral": function () {
      this.form.more_than_one_lateral_included = null;
    },
  },
  mounted: function () {
    this.signaturePad = new SignaturePad.default(this.$refs["signaturePad"]);
    this.signaturePad.addEventListener("endStroke", () => {
      this.setSignature();
    });
    if (this.form.signature_pad != "") {
      this.signaturePad.fromDataURL(this.form.signature_pad, {
        width: 400,
        height: 200,
      });
      this.certified = true;
    } else if (this.user.profile.signature != "") {
      this.signaturePad.fromDataURL(this.user.profile.signature);
      this.form.signature_pad = this.user.profile.signature;
      this.certified = true;
    }
    const now = new Date();
    if (this.form.time === null) {
      this.form.time = now.toTimeString().substring(0, 5);
    } else {
      this.form.time = this.form.time.substring(0, 5);
    }
    if (this.form.date === null) {
      this.form.date = now.toLocaleDateString("en-CA");
    }
  },
};
</script>
<style>
.checkbox-indent {
  margin-left: 25px;
}
.special-question {
  border: 1px dashed;
  padding: 5px 10px;
}
</style>
