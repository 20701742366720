<template>
    <div class="row">
        <p>
            Hello. You’ve reached this page because your municipality hasn’t subscribed to our long-term data storage plan. Please view the Terms and Services signed with your municipality and reach out to learn more about viewing legacy inspections that fall outside our 1 year complementary storage agreement.
        </p>
        <b-button :to="{name: 'inspection-list'}">Back to inspections</b-button>
    </div>
</template>
<script>
export default {
    name: 'LongTermPlan'
}
</script>
