<template>
  <div class="spinner-container text-center mr-2">
    <b-spinner :small="small" :variant="variant" class="align-middle"></b-spinner>
  </div>
</template>
<script>
  export default {
    name: 'Spinner',
    props: {
      small: {
        type: Boolean,
        default: false
      },
      variant: {
        type: String,
        default: 'danger'
      }
    }
  }
</script>
<style>
.spinner-container {
  display: inline-block;
}
</style>
