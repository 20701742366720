<template>
  <div v-if="inspection">
    <div class="row mb-3" :class="{ 'd-block': print }">
      <div class="col">
        <div class="row">
          <div class="col">
            <h2>{{ inspection.site_info.address }}</h2>
            <h4>
              Date of Inspection:
              <small>{{ inspection.date | formatDate }}</small>
            </h4>
            <h4 v-if="displayreview && !pooinspection && inspection.cityreview">
              Date of Municipality Review:
              <small>{{
                inspection.cityreview.modified | dateTimeFormat
              }}</small>
            </h4>
            <h4 v-if="inspection.site_info.project_name">
              Project: <small>{{ inspection.site_info.project_name }}</small>
            </h4>
          </div>
          <div class="col-md-6 text-md-right mb-2 mb-md-0">
            <span v-show="!print">
              <b-button
                class="mt-1"
                variant="success"
                v-if="districtUser"
                @click.prevent="reviewNotification"
                :disabled="!inspection.cityreview"
                v-b-popover.hover="
                  'Send email to both the property contact and inspector with current review status.'
                "
              >
                <font-awesome-icon icon="envelope" /> Re-Send Notifications
              </b-button>
              <b-button
                class="mt-1"
                variant="success"
                v-if="!districtUser"
                @click.prevent="notify"
                :disabled="inspection.status === 'IN_PROGRESS'"
                v-b-popover.hover="
                  'Send email to both the property contact and inspector with current review status.'
                "
              >
                <font-awesome-icon icon="envelope" /> Re-Send Notifications </b-button
              >&nbsp;
              <b-dropdown class="mt-1" id="shareLink" boundary="window" right>
                <template #button-content>
                  <font-awesome-icon icon="share" /> Share
                </template>
                <b-dropdown-form>
                  <CopyInput :text="inspection.frontend_link"></CopyInput>
                </b-dropdown-form> </b-dropdown
              >&nbsp;
              <b-button class="mt-1" :href="pdfurl" v-if="userLoaded">
                <font-awesome-icon icon="file-pdf" /> PDF </b-button
              >&nbsp;
              <b-button class="mt-1" @click="printWindow">
                <font-awesome-icon icon="print" /> Print </b-button
              >&nbsp;
            </span>
            <span v-show="print">
              <b-button class="mt-1" variant="success" @click="unPrint"
                >Return to non-print view</b-button
              >
            </span>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <b-card
              :title="site.name"
              :sub-title="site.owner_type"
              class="inspectionDetailCard"
            >
              <b-card-text>
                <ul class="list-unstyled">
                  <li>
                    <font-awesome-icon icon="envelope" class="mr-2" />
                    {{ site.email }}
                  </li>
                  <li v-for="(e, i) in site.other_email" :key="i">
                    <font-awesome-icon icon="envelope" class="mr-2" /> {{ e }}
                  </li>
                  <li>
                    <font-awesome-icon icon="phone" class="mr-2" />
                    {{ site.phone }}
                  </li>
                  <li>
                    <font-awesome-icon icon="home" class="mr-2" />
                    {{ inspection.site_info.owner_mailing_address }}
                  </li>
                  <li class="pl-4" v-if="districtUser">
                    <AddressExtra :site="inspection.site_info"></AddressExtra>
                  </li>
                  <li class="pl-4" v-else>
                    {{ inspection.site_info.address_extra }}
                  </li>
                </ul>
              </b-card-text>
            </b-card>
          </div>
          <div class="col-md-6 mt-2 mt-md-0">
            <b-card
              :title="inspector.name"
              sub-title="Inspector"
              class="inspectionDetailCard"
            >
              <b-card-text>
                <ul class="list-unstyled">
                  <li>
                    <font-awesome-icon icon="envelope" class="mr-2" />
                    {{ inspector.email }}
                  </li>
                  <li>
                    <font-awesome-icon icon="phone" class="mr-2" />
                    {{ inspector.phone }}
                  </li>
                  <li>
                    <font-awesome-icon icon="home" class="mr-2" />
                    {{ inspector.company.name }}
                  </li>
                  <li class="ml-1" v-if="inspector.licenseNumber">
                    <font-awesome-icon icon="id-badge" class="mr-2" />
                    <span class="ml-1">
                      Lic. No.
                      <a :href="this.licenseLink" target="_blank">{{
                        inspector.licenseNumber
                      }}</a>
                    </span>
                  </li>
                  <li v-if="inspector.company.website">
                    <font-awesome-icon icon="link" class="mr-2" />
                    <a :href="inspector.company.website" target="_blank">{{
                      inspector.company.website
                    }}</a>
                  </li>
                </ul>
              </b-card-text>
            </b-card>
          </div>
        </div>
      </div>
    </div>
    <div class="row" :class="{ 'd-block': print }">
      <div class="col">
        <b-tabs
          content-class="mt-0"
          @activate-tab="checkdirty"
          v-if="!print"
          v-model="tabindex"
        >
          <b-tab title="Video" title-item-class="bigtab">
            <div class="border border-top-0 p-2">
              <DefectCreate
                v-if="districtUser"
                :inspection="inspection"
                @defectssaved="defectssaved"
                @defectchanged="defectchanged"
                savetext="Save"
              ></DefectCreate>
              <DefectList v-else :inspection="inspection"></DefectList>
            </div>
          </b-tab>
          <b-tab title="Inspection Form" title-item-class="bigtab">
            <div class="border border-top-0">
              <InspectionFormDetail
                :inspection="inspection"
              ></InspectionFormDetail>
            </div>
          </b-tab>
          <b-tab
            title="Municipality Review"
            v-if="displayreview && !pooinspection"
            title-item-class="bigtab"
          >
            <div v-if="districtUser" class="border border-top-0 p-2">
              <UpdateReview
                v-if="inspection.cityreview"
                :inspection="inspection"
              ></UpdateReview>
              <NewReview v-else :inspection="inspection"></NewReview>
            </div>
            <div v-else class="border border-top-0 p-2">
              <ReviewDetail
                :review="inspection.cityreview"
                :inspection="inspection"
              ></ReviewDetail>
            </div>
          </b-tab>
        </b-tabs>
        <div v-else>
          <h3>Municipality Review</h3>
          <ReviewDetail
            :review="inspection.cityreview"
            :inspection="inspection"
          ></ReviewDetail>
          <h3>Inspection Form</h3>
          <InspectionFormDetail :inspection="inspection"></InspectionFormDetail>
          <h3>Observations</h3>
          <DefectList :inspection="inspection"></DefectList>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import InspectionFormDetail from "./InspectionFormDetail";
import DefectList from "../defects/DefectList";
import ReviewDetail from "../reviews/ReviewDetail";
import UpdateReview from "../reviews/UpdateReview";
import NewReview from "../reviews/NewReview";
import DefectCreate from "../defects/DefectCreate";
import AddressExtra from "../sites/AddressExtra";
import CopyInput from "@/components/util/CopyInput";
import { mapGetters } from "vuex";
import { HTTP } from "@/http";
import moment from "moment";

export default {
  name: "InspectionDetail",
  components: {
    InspectionFormDetail,
    DefectList,
    ReviewDetail,
    UpdateReview,
    NewReview,
    DefectCreate,
    AddressExtra,
    CopyInput,
  },
  props: {
    displayreview: {
      type: Boolean,
      default: function () {
        return true;
      },
    },
  },
  data: function () {
    let tabindex = 0;
    if (this.$route.query.tab) {
      tabindex = this.$route.query.tab;
    }
    return {
      print: false,
      dirty: false,
      tabindex: tabindex,
    };
  },
  mounted: function () {
    if (this.signature) {
      this.$store.dispatch("fetchInspectionWithSig", {
        inspectionId: this.inspectionId,
        signature: this.signature,
      });
    } else {
      this.$store.dispatch("fetchInspection", this.inspectionId).then(() => {
        if (this.inspection.cityreview && this.displayreview) {
          this.tabindex = 2;
        }
      });
    }
  },
  computed: {
    inspectionId: function () {
      return this.$route.params.inspectionId;
    },
    dateOfInspection: function () {
      return new Date(this.inspection.date).toLocaleString();
    },
    site: function () {
      const site_info = this.inspection.site_info;
      return {
        name: site_info.owner_first_name + " " + site_info.owner_last_name,
        email: site_info.owner_email,
        phone: site_info.owner_phone,
        mailing_address: site_info.owner_mailing_address,
        other_email: site_info.other_email,
        owner_type: site_info.owner_type,
      };
    },
    inspector: function () {
      const inspector_info = this.inspection.inspector_info;
      return {
        name: inspector_info.first_name + " " + inspector_info.last_name,
        email: inspector_info.email,
        company: inspector_info.profile.company,
        phone: inspector_info.profile.phone,
        licenseNumber: inspector_info.profile.company.license_number,
      };
    },
    licenseLink: function () {
      return (
        "https://www.cslb.ca.gov/OnlineServices/CheckLicenseII/LicenseDetail.aspx?LicNum=" +
        this.inspector.licenseNumber
      );
    },
    districtUser: function () {
      return this.$store.getters.districtUser;
    },
    inspectorUser() {
      return this.$store.getters.inspectorUser;
    },
    signature: function () {
      return this.$route.query.signature;
    },
    pooinspection: function () {
      return this.inspection.type === "POO";
    },
    userLoaded: function () {
      return this.$store.getters.user.loaded;
    },
    pdfurl: function () {
      return (
        process.env.VUE_APP_API_ROOT +
        "/api/inspections/" +
        this.inspection.id +
        "/report/"
      );
    },
    ...mapGetters(["inspection"]),
  },
  methods: {
    printWindow: function () {
      this.print = true;
      setTimeout(() => {
        window.print();
      }, 1000);
    },
    unPrint: function () {
      this.print = false;
    },
    defectssaved: function () {
      this.$nextTick(function () {
        this.dirty = false;
      });
    },
    defectchanged: function () {
      if (this.tabindex == 2) {
        this.dirty = true;
      }
    },
    checkdirty: function (newt, prevt, event) {
      if (this.dirty && this.tabindex == 2) {
        event.preventDefault();
        const choice = confirm(
          "You have made unsaved changes to observations. Continue?"
        );
        if (choice) {
          this.dirty = false;
          this.tabindex = newt;
        }
      }
    },
    reviewNotification() {
      HTTP.post(
        `/api/cityreviews/${this.inspection.cityreview.id}/review_notifications/`
      )
        .then(() => {
          this.$store.commit("setMessage", {
            message: "Notifications successfully sent.",
          });
        })
        .catch((e) => {
          console.log(e);
          this.$store.commit("setMessage", {
            message:
              "Could not send notifications. Please try again. " + e.data,
            variant: "danger",
          });
        });
    },
    notify() {
      HTTP.post(`/api/inspections/${this.inspectionId}/resend_notification/`)
        .then(() => {
          this.$store.commit("setMessage", { message: "Notification sent" });
        })
        .catch((e) => {
          this.$store.commit("setMessage", { message: e, variant: "danger" });
        });
    },
  },
  filters: {
    dateTimeFormat: function (value) {
      return moment(value).format("M/D/YYYY");
    },
  },
};
</script>
<style>
.inspectionDetailCard {
  min-height: 215px;
}
.bigtab {
  font-size: 1.5em;
  font-weight: bold;
  border: 1px solid #dee2e6;
}
</style>
