<template>
  <div class="container-fluid">
    <b-form @submit.prevent="onSubmit">
      <div class="form-row">
        <b-form-group
          id="input-group-name"
          class="col-sm"
          label="Question Set Name"
          label-for="input-name"
        >
          <b-form-input
            id="input-name"
            v-model="form.name"
            type="text"
            required
          ></b-form-input>
        </b-form-group>
      </div>
      <div class="form-row">
        <b-button variant="success" type="submit">Save</b-button>
      </div>
    </b-form>
  </div>
</template>
<script>
export default {
  name: 'QuestionSetForm',
  props: {
    initial : {
      type: Object,
      default() {
        return {
          name: '',
        }
      }
    }
  },
  data() {
    return {
      form: { ...this.initial },
    }
  },
  methods: {
    onSubmit() {
      this.$emit('form-submitted', this.form)
    }
  },
  watch: {
    initial() {
      this.form = {...this.initial}
    }
  }
}
</script>
