import Vue from 'vue'

// import BootstrapVue from 'bootstrap-vue'
// Vue.use(BootstrapVue)
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'


import {
  LayoutPlugin, NavbarPlugin, ButtonPlugin, AlertPlugin, BreadcrumbPlugin, CardPlugin,
  FormPlugin, FormGroupPlugin, FormCheckboxPlugin, FormInputPlugin, FormSelectPlugin,
  FormTextareaPlugin, InputGroupPlugin, ImagePlugin, LinkPlugin, SpinnerPlugin,
  TablePlugin, ButtonGroupPlugin, TabsPlugin, FormRadioPlugin, FormFilePlugin,
  TooltipPlugin, PaginationPlugin, ModalPlugin, VBPopoverPlugin, ListGroupPlugin
} from 'bootstrap-vue'

Vue.use(LayoutPlugin)
Vue.use(NavbarPlugin)
Vue.use(ButtonPlugin)
Vue.use(AlertPlugin)
Vue.use(BreadcrumbPlugin)
Vue.use(CardPlugin)
Vue.use(FormPlugin)
Vue.use(FormGroupPlugin)
Vue.use(FormCheckboxPlugin)
Vue.use(FormInputPlugin)
Vue.use(FormSelectPlugin)
Vue.use(FormTextareaPlugin)
Vue.use(ImagePlugin)
Vue.use(InputGroupPlugin)
Vue.use(LinkPlugin)
Vue.use(SpinnerPlugin)
Vue.use(TablePlugin)
Vue.use(ButtonGroupPlugin)
Vue.use(TabsPlugin)
Vue.use(FormRadioPlugin)
Vue.use(FormFilePlugin)
Vue.use(TooltipPlugin)
Vue.use(PaginationPlugin)
Vue.use(ModalPlugin)
Vue.use(VBPopoverPlugin)
Vue.use(ListGroupPlugin)
