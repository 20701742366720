<template>
  <b-form-group
    :id="'input-group-' + eleId"
    :label="question.label"
    :label-for="'input-' + eleId"
  >
    <b-form-select
      :id="'input-' + eleId"
      v-model="value"
      :options="options"
      :required="question.required"
      @change="sendAnswer"
    ></b-form-select>
  </b-form-group>
</template>
<script>
export default {
  name: 'FbDropdown',
  props: {
    question: Object,
    initial: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      value: this.initial === null ? this.question.value : this.initial
    }
  },
  computed: {
    eleId() {
      return `question-${this.question.id}`
    },
    options() {
      return this.question.choices.map(c => {
        return {value: c.value, text: c.name}
      })
    }
  },
  methods:{
    sendAnswer(){
      this.$emit('answered', {answer: this.value, question: this.question.id})
    }
  },
  mounted(){
    this.sendAnswer()
  }
}
</script>
