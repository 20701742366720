<template>
  <div class="row">
    <div class="questions p-2">
      <b-list-group>
        <span v-for="questionSet in questionSets" :key="questionSet.id" @click="selectQuestionSet(questionSet)">
          <question-set-detail :questionSet="questionSet" :selected="selectedQuestionSet === questionSet"></question-set-detail>
        </span>
      </b-list-group>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import QuestionSetDetail from './QuestionSetDetail.vue'
export default {
  name: 'QuestionSetList',
  components: {
    QuestionSetDetail
  },
  data(){
    return {
    }
  },
  mounted(){
    this.$store.dispatch('fetchQuestionSets')
  },
  methods:{
    selectQuestionSet(questionSet) {
      this.$store.commit('setSelectedQuestionSet', questionSet)
    }
  },
  computed: {
    ...mapState({
      questionSets: state => state.formbuilder.questionSets,
      selectedQuestionSet: state => state.formbuilder.selectedQuestionSet
    })
  }
}
</script>
<style>
  .questions {
    overflow-y: scroll;
    height: 100%;
    background: darkgray;
  }
</style>
