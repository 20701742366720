<template>
  <div>
    <div class="row">
      <div class="col">
        <Inspections></Inspections>
      </div>
    </div>
  </div>
</template>
<script>
  import Inspections from './inspections/Inspections'
  export default {
    name: 'Home',
    components: {Inspections},
    data: function(){
      return {}
    }
  }
</script>
