<template>
  <div>
    <div class="row">
      <div class="col-md-2">
        <h3 class="mb-3">Admin</h3>
      </div>
      <div class="col-md-2">
        <b-form-group
          label-size="sm"
          label-for="startDate"
          label="Start"
          label-cols="3"
          class="mb-0"
        >
          <b-form-input
            size="sm"
            v-model="startdate"
            type="date"
            id="startDate"
          ></b-form-input>
        </b-form-group>
      </div>
      <div class="col-md-2">
        <b-form-group
          label-size="sm"
          label-for="endDate"
          label="End"
          label-cols="3"
          class="mb-0"
        >
          <b-form-input
            size="sm"
            v-model="enddate"
            type="date"
            id="endDate"
          ></b-form-input>
        </b-form-group>
      </div>
      <div class="col-md-3">
        <b-form-group
          label-size="sm"
          id="input-group-10"
          label="Municipality"
          label-cols="4"
          label-for="input-10"
        >
          <b-form-select
            id="input-10"
            size="sm"
            :options="districtChoices"
            v-model="district"
          ></b-form-select>
        </b-form-group>
      </div>
      <div class="col-md-2">
        <b-form-group
          label-align-sm="right"
          label-size="sm"
          label-for="filterInput"
          class="mb-0"
        >
          <b-input-group size="sm">
            <b-form-input
              v-model="filter"
              type="search"
              id="filterInput"
              placeholder="Type to Search"
            ></b-form-input>
            <b-input-group-append>
              <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </div>
      <div class="col-md-1">
        <b-button :href="spreadsheetURL" target="_blank">Excel</b-button>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
      <b-tabs content-class="pt-2 border border-top-0">
        <b-tab title="By User">
          <AdminUserTable :filter="filter" :startdate="startdate" :enddate="enddate" :district="district"></AdminUserTable>
        </b-tab>
        <b-tab title="By Site">
          <AdminSiteTable :filter="filter" :startdate="startdate" :enddate="enddate" :district="district"></AdminSiteTable>
        </b-tab>
      </b-tabs>
    </div>
    </div>
  </div>
</template>
<script>
  import {HTTP} from '../../http.js'
  import AdminSiteTable from './AdminSiteTable'
  import AdminUserTable from './AdminUserTable'
  export default {
    name: 'Admin',
    components: {AdminSiteTable, AdminUserTable},
    data: function(){
      var date = new Date()
      return {
        filter: '',
        districtChoices: [{text: 'Filter by municipality', value: null}],
        district: null,
        startdate: new Date(date.getFullYear(), date.getMonth(), 1).toLocaleDateString('en-CA'),
        enddate: new Date(date.getFullYear(), date.getMonth() + 1, 0).toLocaleDateString('en-CA'),
      }
    },
    computed: {
      spreadsheetURL() {
        let url = `${process.env.VUE_APP_API_ROOT}/api/admin/users/spreadsheet/?start_date=${this.startdate}&end_date=${this.enddate}&search=${this.filter}`
        if (this.district != null){
          url = url + '&district=' + this.district
        }
        return url
      },
    },
    mounted() {
      HTTP.get('/api/districts/').then(response =>{
        const choices = response.data.map(district => {
          return {text: district.name, value: district.id}
        })
        this.districtChoices.push(...choices)
      })
    }
  }
</script>
