<template>
    <div>
      <question-set-form @form-submitted="saveQuestionSet"></question-set-form>
    </div>
</template>
<script>
import QuestionSetForm from './QuestionSetForm.vue'
export default {
  name: 'QuestionSetCreate',
  components: {
    QuestionSetForm
  },
  methods: {
    saveQuestionSet(questionSet) {
      this.$store.dispatch('createQuestionSet', questionSet)
    }
  }
}
</script>
