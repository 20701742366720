<template>
  <div class="p-3">
    <div class="row">
      <div class="col-md-auto">
        <div class="row">
          <div class="mb-5 text-center" :class="fullscreen ? 'col-sm-12' : 'col-xl-6 col-lg-12'">
            <video ref="video" class="embed-responsive-item mb-2 pl-4" controls>
              <source :src="inspection.last_video.signed_url" />
            </video>
            <b-button class="btn-success m-auto mb-2" :href="inspection.last_video.signed_url" download="forwardlateral.mp4">
              <font-awesome-icon icon="download"></font-awesome-icon> Download Video
            </b-button>
            <b-button class="ml-1" variant="primary" :pressed="fullscreen === true" @click="setFullScreen"><font-awesome-icon icon="expand" title="fullscreen"></font-awesome-icon></b-button>
          </div>
          <div class="col">
            <DefectDetail v-for="(defect, i) in inspection.defects" :defect="defect" :key="i" :index="i + 1" v-on:mark-set="setPlaybackPosition"></DefectDetail>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import DefectDetail from './DefectDetail'
  export default {
    name: 'DefectList',
    props: ['inspection'],
    components: {DefectDetail},
    data: function(){
      return {
        fullscreen: false
      }
    },
    methods:{
      setPlaybackPosition: function(time){
        this.$refs.video.currentTime = time
        this.$refs.video.play()
        this.$refs.video.pause()
      },
      setFullScreen: function(){
        this.fullscreen = !this.fullscreen
      },
    }
  }
</script>
<style>
video {
  max-width: 100%;
}
</style>
