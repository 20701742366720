<template>
  <div class="col-md-12">
    <b-table
      striped
      :items="getSites"
      :fields="fields"
      :per-page="perPage"
      primary-key="id"
      :sort-by="sortBy"
      :sort-desc="sortDesc"
      :current-page="currentPage"
      :filter="filter"
      ref="table"
      show-empty
      small
      outlined
    >
      <template v-slot:table-busy>
        <b-spinner class="align-middle"></b-spinner>
      </template>
      <template v-slot:empty>
        <div class="text-center">
          <p>No properties have been created</p>
        </div>
      </template>
      <template v-slot:cell(inspections[0].date)="data">
        <span v-if="data.item.inspections.length > 0">
          <b-link
            :to="{
              name: 'inspection-detail',
              params: { inspectionId: data.item.inspections[0].id },
            }"
          >
            {{ data.item.inspections[0].date }}
          </b-link>
        </span>
      </template>
      <template v-slot:cell(owner_first_name)="data">
        {{ data.item.owner_first_name }} {{ data.item.owner_last_name }}
      </template>
    </b-table>
    <div class="overflow-auto">
      <b-pagination
        v-model="currentPage"
        :total-rows="total"
        :per-page="perPage"
        aria-controls="my-table"
        align="right"
      >
      </b-pagination>
    </div>
  </div>
</template>
<script>
import { HTTP } from "../../http";
export default {
  name: "AdminSiteTable",
  props: ["filter", "startdate", "enddate", "district"],
  data: function () {
    return {
      sortBy: "owner_first_name",
      currentPage: 1,
      perPage: 20,
      total: 0,
      sortDesc: true,
      fields: [
        {
          key: "address",
          sortable: true,
          label: "Address",
          formatter: "address",
        },
        { key: "owner_first_name", sortable: true, label: "Contact Name" },
        { key: "district_info.name", sortable: true, label: "Municipality" },
        {
          key: "inspection_count",
          sortable: false,
          label: "Total Inspections",
        },
        {
          key: "first_inspection.date",
          sortable: false,
          label: "First Inspection",
        },
        {
          key: "first_inspection.inspector_info.profile.company.name",
          sortable: false,
          label: "First Inspection Company",
        },
        {
          key: "first_inspection.inspector_info.full_name",
          sortable: false,
          label: "First Inspection Inspector",
        },
      ],
    };
  },
  methods: {
    keyToOrder(key) {
      switch (key) {
        case "address":
          return "address";
        case "owner_first_name":
          return "owner_first_name";
        case "district_info.name":
          return "district__name";
      }
    },
    getSites: function (ctx) {
      const orderDir = ctx.sortDesc ? "-" : "";
      const orderKey = this.keyToOrder(ctx.sortBy);
      const ordering = orderDir + orderKey.split(",").join("," + orderDir);
      let url =
        "/api/sites/admin/?page=" +
        ctx.currentPage +
        "&start_date=" +
        this.startdate +
        "&end_date=" +
        this.enddate +
        "&order=" +
        ordering +
        "&search=" +
        this.filter;
      if (this.district != null) {
        url = url + "&district=" + this.district;
      }
      const promise = HTTP.get(url);
      return promise
        .then((data) => {
          const items = data.data.results;
          this.total = data.data.count;
          return items || [];
        })
        .catch((e) => {
          console.log(e);
        });
    },
    address: function (value) {
      let parts = value.split(",");
      const addresscity = parts.splice(0, 2);
      return addresscity.join(",");
    },
    firstInspection: function (value) {
      if (value.length > 0) {
        return value[0];
      } else {
        return "None";
      }
    },
  },
  watch: {
    startdate: function () {
      this.$refs.table.refresh();
    },
    enddate: function () {
      this.$refs.table.refresh();
    },
    district: function () {
      this.$refs.table.refresh();
    },
  },
};
</script>
