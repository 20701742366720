<template>
  <div>
    <div v-if="!user">
      <Spinner></Spinner>
    </div>
    <div v-else>
      <b-alert v-model="hasError" variant="danger">
        <p>
          There were errors with your submission. Please correct them below:
        </p>
        <ul>
          <li v-for="(error, field) in errors" v-bind:key="field">
            {{ field }}: <span v-for="e in error" v-bind:key="e">{{ e }}</span>
          </li>
        </ul>
      </b-alert>
      <b-form @submit.prevent="onSubmit">
        <div class="form-row">
          <b-form-group
            id="input-group-first-name"
            class="col-sm"
            label="First Name"
            label-for="input-first-name"
          >
            <b-form-input
              id="input-first-name"
              v-model="user.first_name"
              required
            ></b-form-input>
          </b-form-group>
          <b-form-group
            id="input-group-email"
            class="col-sm"
            label="Last Name"
            label-for="input-last-name"
          >
            <b-form-input
              id="input-last-name"
              v-model="user.last_name"
              required
            ></b-form-input>
          </b-form-group>
          <b-form-group
            id="input-group-email"
            class="col-sm"
            label="Email"
            label-for="input-email"
          >
            <b-form-input
              id="input-email"
              v-model="user.email"
              type="email"
              required
            ></b-form-input>
          </b-form-group>
          <b-form-group
            id="input-group-phone"
            class="col-sm"
            label="Phone"
            label-for="input-phone"
          >
            <b-form-input
              id="input-phone"
              v-model="user.profile.phone"
              v-mask="'(###) ###-####'"
              required
            ></b-form-input>
          </b-form-group>
        </div>
        <div class="form-row">
          <b-form-group
            id="input-group-address_line_1"
            class="col-sm"
            label="Address Line 1"
            label-for="input-address_line_1"
          >
            <b-form-input
              id="input-address_line_1"
              v-model="user.profile.address_line_1"
              required
            ></b-form-input>
          </b-form-group>
          <b-form-group
            id="input-group-address_line_2"
            class="col-sm"
            label="Address Line 2 (optional)"
            label-for="input-address_line_2"
          >
            <b-form-input
              id="input-address_line_2"
              v-model="user.profile.address_line_2"
            ></b-form-input>
          </b-form-group>
        </div>
        <div class="form-row">
          <b-form-group
            id="input-group-address_city"
            class="col-sm"
            label="City"
            label-for="input-address_city"
          >
            <b-form-input
              id="input-address_city"
              v-model="user.profile.address_city"
            ></b-form-input>
          </b-form-group>
          <b-form-group
            id="input-group-address_state"
            class="col-sm"
            label="State"
            label-for="input-address_state"
          >
            <b-form-select
              id="input-address_state"
              v-model="user.profile.address_state"
              :options="states"
            ></b-form-select>
          </b-form-group>
          <b-form-group
            id="input-group-address_zip"
            class="col-sm"
            label="Zipcode"
            label-for="input-address_zip"
          >
            <b-form-input
              id="input-address_zip"
              v-model="user.profile.address_zip"
            ></b-form-input>
          </b-form-group>
        </div>
        <div class="form-row">
          <b-form-checkbox
            id="input-email-notifications"
            v-model="user.profile.email_notifications"
            name="email-notifications"
            class="mb-2"
          >
            Recieve email alerts on new inspections from Forward Lateral.
          </b-form-checkbox>
        </div>
        <b-form-row>
          <b-button type="submit" variant="primary" :disabled="loading">
            <Spinner :small="true" variant="dark" v-show="loading"></Spinner
            >Save
          </b-button>
        </b-form-row>
      </b-form>
    </div>
  </div>
</template>
<script>
import { HTTP } from "@/http";
import Spinner from "@/components/util/Spinner";
export default {
  name: "ProfileForm",
  components: { Spinner },
  data: function () {
    return {
      hasError: false,
      loading: false,
      errors: [],
      user: null,
      states: [],
    };
  },
  mounted() {
    this.fetchUser();
    this.fetchStates();
  },
  methods: {
    fetchStates() {
      HTTP.get("/api/accounts/states/").then((response) => {
        this.states = response.data.map((state) => {
          return { value: state[0], text: state[1] };
        });
      });
    },
    fetchUser() {
      HTTP.get("/api/accounts/user/")
        .then((response) => {
          this.user = response.data;
        })
        .catch((e) => {
          this.$store.commit("setMessage", { message: e, variant: "error" });
        });
    },
    onSubmit() {
      this.loading = true;
      HTTP.put("/api/accounts/user/", this.user)
        .then((response) => {
          this.user = response.data;
          this.$store.commit("setMessage", {
            message: "Account Saved",
            variant: "info",
            showfor: 3,
          });
          this.$store.commit("setUserData", this.user);
        })
        .catch((e) => {
          this.$store.commit("setMessage", { message: e, variant: "error" });
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
