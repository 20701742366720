<template>
  <div>
    <InspectionTableDistrict v-if="districtUser"></InspectionTableDistrict>
    <b-tabs content-class="mt-3" v-else>
      <b-tab title="Municipality Review" title-item-class="bigtab">
        <p>
          Submit to municipality. Your client gets a copy by email when
          submitted & reviewed.
        </p>
        <InspectionTableInspector
          :loaded="loaded"
          type="DISTRICT"
        ></InspectionTableInspector>
      </b-tab>
      <b-tab
        title="Home Inspections"
        title-item-class="bigtab"
        v-if="user.profile.municipality_inspections"
      >
        <p>Submit only to your client, with no municipality involved.</p>
        <InspectionTableInspector
          :loaded="loaded"
          type="POO"
        ></InspectionTableInspector>
      </b-tab>
    </b-tabs>
  </div>
</template>
<script>
import InspectionTableInspector from "./InspectionTableInspector";
import InspectionTableDistrict from "./InspectionTableDistrict";
export default {
  name: "Inspections",
  components: {
    InspectionTableInspector,
    InspectionTableDistrict,
  },
  data: function () {
    return {
      loaded: false,
      inspections: [],
    };
  },
  created: function () {
    this.$store.dispatch("clearInspection");
  },
  computed: {
    districtUser: function () {
      return this.$store.getters.districtUser;
    },
    user: function () {
      return this.$store.getters.user;
    },
  },
};
</script>
<style>
.bigtab {
  font-size: 1.5em;
  font-weight: bold;
}
</style>
