<template>
  <div>
  <b-alert v-model="hasError" variant="danger">
    <p>There was an error: {{ error }} Please contact support if this problem persists.</p>
  </b-alert>
  <Uppy v-if="signature" :mediaType="mediaType" :params="params" :fields="fields"
        :signature="signature" v-on:upload-success="uploadComplete" v-on:upload-failed="uploadFailed" v-on:upload-reset="uploadReset"
  ></Uppy>
  </div>
</template>
<script>
  import {HTTP} from '../../http'
  import {utcDateString} from '../../utils'
  import Uppy from './Uppy'
  export default {
    name: 'MediaUpload',
    components: {
      Uppy: Uppy
    },
    props: ['mediaType', 'inspection'],
    data: function(){
      const expiresIn  = 1000 * 60 * 60 * 24
      const expires = utcDateString((+new Date()) + expiresIn)
      let template_id = process.env.VUE_APP_TRANSLOADIT_ATTACHMENT_TEMPLATE
      if(this.mediaType == 'VIDEO'){
        template_id = process.env.VUE_APP_TRANSLOADIT_VIDEO_TEMPLATE
      }else if(this.mediaType == 'PROPERTY_PHOTO'){
        template_id = process.env.VUE_APP_TRANSLOADIT_IMAGE_TEMPLATE
      }
      return {
        signature: null,
        params: {
           auth: {
            key: process.env.VUE_APP_TRANSLOADIT_PUBLIC_KEY,
            expires: expires
          },
          //notify_url: process.env.VUE_APP_API_ROOT + '/api/inspections/' + this.inspection.id + '/notify/',
          template_id: template_id
        },
        fields: {
          media_type: this.mediaType,
          inspection_id: this.inspection.id,
          inspection_signature: this.inspection.signature
        },
        error: null,
      }
    },
    methods: {
      getSignature: function(){
        return HTTP.post('/api/inspections/' + this.inspection.id + '/sign_transloadit/', this.params).then(response =>{
          this.signature = response.data.signature
        })
      },
      uploadComplete: function(result){
        this.$emit('upload-complete', {result: result, mediaType: this.mediaType})
      },
      uploadFailed: function(failures){
        this.error = 'Video failed to upload.'
        console.log(failures)
      },
      uploadReset: function(){
        this.$emit('upload-reset', this.mediaType)
      }
    },
    computed: {
      hasError: function(){
        return Boolean(this.error)
      }
    },
    mounted: function(){
      this.getSignature()
    }
  }
</script>
