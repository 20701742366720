<template>
  <div class="row justify-content-md-center">
    <div class="col-md-auto">
      <h2>Page not found</h2>
    </div>
  </div>
</template>
<script>
  export default {
    name: 'NotFound'
  }
</script>
