<template>
  <div>
    <question-form @form-submitted="saveQuestion"></question-form>
  </div>
</template>
<script>
import QuestionForm from './QuestionForm.vue'
export default {
  name: 'QuestionUpdate',
  components: {
    QuestionForm
  },
  computed: {
  },
  methods: {
    saveQuestion(question) {
      this.$store.dispatch('createQuestion', question)
    }
  }
}
</script>
