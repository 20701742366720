<template>
  <div id="app">
    <b-navbar toggleable="lg" variant="light">
      <b-navbar-brand v-if="!companyLogo" :to="{ name: 'home' }">
        <b-img src="./assets/flateral-full.png" id="logo" fluid></b-img>
      </b-navbar-brand>
      <b-navbar-brand v-else :to="{ name: 'home' }">
        <b-img :src="companyLogo" id="company-logo" fluid></b-img>
        <b-img src="./assets/flateral-powered-by.png" id="logo" fluid></b-img>
      </b-navbar-brand>

      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav v-if="!districtUser">
          <b-dropdown
            id="new-inspection"
            text="Start a new inspection"
            class="m-md-2"
            block
          >
            <b-dropdown-item
              active-class=""
              :to="{ name: 'site-new', query: { type: 'district' } }"
            >
              Municipality Review
            </b-dropdown-item>
            <b-dropdown-item
              v-if="user.profile.municipality_inspections"
              active-class=""
              :to="{ name: 'site-new', query: { type: 'po' } }"
            >
              Home Inspections
            </b-dropdown-item>
          </b-dropdown>
        </b-navbar-nav>

        <!-- Right aligned nav items -->
        <b-navbar-nav class="ml-auto" right>
          <b-nav-item href="https://vimeo.com/476010529" target="_blank"
            >Watch Demo</b-nav-item
          >
          <b-nav-item href="mailto:jesse@goforwardlateral.com"
            >Contact</b-nav-item
          >
          <b-nav-item :to="{ name: 'account' }">Account</b-nav-item>
          <b-nav-item :href="logoutUrl">Logout</b-nav-item>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
    <b-container class="mt-5 mb-5">
      <b-alert
        :show="message.showfor"
        :variant="message.variant"
        @dismissed="resetMessage"
        dismissible
        class="fixed-top mt-5 shadow"
      >
        {{ message.content }}
      </b-alert>
      <b-alert
        v-if="userLoaded"
        :show="!company.approved && user.profile.municipality_inspections"
        variant="warning"
      >
        Your company has not been approved to submit to any municipalities yet.
        If you have recently created an account please wait 24 hours for
        approval or contact support via
        <a href="mailto:mailto:jesse@goforwardlateral.com">email</a>.
      </b-alert>
      <b-alert :show="unsupportedBrowser" variant="warning">
        You are currently using an unsupported browser. For the best experience,
        please use
        <a
          href="https://www.mozilla.org/en-US/firefox/new/"
          title="Firefox"
          target="_blank"
          >Firefox</a
        >
        or
        <a
          href="https://www.google.com/chrome/index.html"
          title="Chrome"
          target="_blank"
          >Chrome</a
        >.
      </b-alert>
      <Breadcrumb v-show="showBreadCrumb"></Breadcrumb>

      <router-view v-if="userLoaded"></router-view>
      <Spinner v-else></Spinner>
    </b-container>
    <footer class="footer">
      <div class="container text-center">
        <span class="text-muted">
          <font-awesome-icon icon="mobile-alt"></font-awesome-icon>
          <font-awesome-icon icon="tablet"></font-awesome-icon>
          Forward Lateral works great on mobile devices such as phones and
          tablets. </span
        ><br />
        <span class="text-muted">
          © 2024 Forward Lateral ·
          <b-link :to="{ name: 'legal' }">Terms and Conditions</b-link> ·
          <b-link :to="{ name: 'legal' }">Privacy Policy</b-link>
        </span>
      </div>
    </footer>
  </div>
</template>

<script>
import Bowser from "bowser";
import Spinner from "@/components/util/Spinner";
import Breadcrumb from "@/components/util/Breadcrumb";
export default {
  name: "app",
  components: {
    Breadcrumb,
    Spinner,
  },
  data() {
    return {
      anon: false,
    };
  },
  created: function () {
    if (
      this.$route.name === "inspection-detail" &&
      this.$route.query.signature
    ) {
      // this is a public page
      this.anon = true;
    } else {
      this.$store.dispatch("fetchUserData");
    }
  },
  methods: {
    resetMessage() {
      this.$store.dispatch("resetMessage");
    },
  },
  computed: {
    user: function () {
      return this.$store.getters.user;
    },
    fullName: function () {
      return this.$store.getters.fullName;
    },
    message: function () {
      return this.$store.getters.message;
    },
    districtUser: function () {
      return this.$store.getters.districtUser;
    },
    company: function () {
      return this.$store.getters.company;
    },
    companyLogo: function () {
      if (this.$store.getters.inspection) {
        return this.$store.getters.inspection.inspector_info.profile.company
          .logo;
      } else {
        return this.company.logo;
      }
    },
    unsupportedBrowser: function () {
      const browser = Bowser.getParser(window.navigator.userAgent);
      return ["Internet Explorer", "Microsoft Edge"].includes(
        browser.getBrowser().name
      );
    },
    logoutUrl: function () {
      return process.env.VUE_APP_API_ROOT + "/accounts/logout/";
    },
    profileUrl: function () {
      return process.env.VUE_APP_API_ROOT + "/accounts/profile/";
    },
    showBreadCrumb: function () {
      return [
        "inspection-update",
        "inspection-property",
        "inspection-media",
        "inspection-eval",
        "submit-to-city",
        "site-new",
        "site-update",
        "inspection-new",
      ].includes(this.$route.name);
    },
    userLoaded() {
      return this.$store.state.user.loaded || this.anon;
    },
  },
};
</script>
<style>
html {
  position: relative;
  min-height: 100%;
}
body {
  margin-bottom: 60px;
}
#logo {
  max-height: 50px;
}
#company-logo {
  max-height: 50px;
  padding-right: 5px;
  border-right: 2px solid #3d3d3d;
}
.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 60px;
  line-height: 30px;
  font-size: 0.8em;
  background-color: #f5f5f5;
}
.framed {
  background-color: #f5f5f5;
  padding: 10px;
  border-radius: 5px;
}
.linebreaks {
  white-space: pre-wrap;
}
</style>
