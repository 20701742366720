<template>
  <div>
    <div v-if="selectedQuestion">
      <question-form :initial="selectedQuestion" @form-submitted="saveQuestion" :readonly-fields="true"></question-form>
    </div>
    <div v-else class="p-5">
      <h4>Please select a question from the list on the left.</h4>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import QuestionForm from './QuestionForm.vue'
export default {
  name: 'QuestionUpdate',
  components: {
    QuestionForm
  },
  computed: {
    ...mapState({
      selectedQuestion: state => state.formbuilder.selectedQuestion
    }),
  },
  methods: {
    saveQuestion(question) {
      this.$store.dispatch('updateQuestion', question)
    }
  }
}
</script>
