<template>
  <div class="col-md-12">
    <b-table
      striped
      :items="getUsers"
      :fields="fields"
      primary-key="id"
      :sort-by="sortBy"
      :sort-desc="sortDesc"
      :filter="filter"
      :per-page="perPage"
      :current-page="currentPage"
      show-empty
      small
      outlined
      ref="table"
    >
      <template v-slot:table-busy>
        <b-spinner class="align-middle"></b-spinner>
      </template>
      <template v-slot:empty>
        <div class="text-center">
          <p>No Users have been added</p>
        </div>
      </template>
      <template v-slot:cell(full_name)="data">
        <b-link :href="adminLink(data.item.id)">{{
          data.item.full_name
        }}</b-link>
      </template>
    </b-table>
    <div class="overflow-auto">
      <b-pagination
        v-model="currentPage"
        :total-rows="total"
        :per-page="perPage"
        aria-controls="my-table"
        align="right"
      >
      </b-pagination>
    </div>
  </div>
</template>
<script>
import { HTTP } from "../../http";
export default {
  name: "AdminUserTable",
  props: ["filter", "startdate", "enddate", "district"],
  data: function () {
    return {
      currentPage: 1,
      perPage: 20,
      total: 0,
      sortBy: "full_name",
      sortDesc: true,
      fields: [
        { key: "full_name", sortable: true },
        { key: "email", sortable: true },
        { key: "profile.company.name", sortable: true, label: "Company" },
        {
          key: "first_inspections",
          sortable: false,
          label: "First Inspections",
        },
        {
          key: "inspection_count",
          sortable: false,
          label: "Total Inspections",
        },
        {
          key: "submitted_inspections",
          sortable: false,
          label: "Submitted Inspections",
        },
      ],
    };
  },
  methods: {
    keyToOrder(key) {
      switch (key) {
        case "full_name":
          return "first_name";
        case "profile.company.name":
          return "profile__company__name";
      }
    },
    getUsers: function (ctx) {
      const orderDir = ctx.sortDesc ? "-" : "";
      const orderKey = this.keyToOrder(ctx.sortBy);
      const ordering = orderDir + orderKey.split(",").join("," + orderDir);
      let url =
        "/api/admin/users/?page=" +
        ctx.currentPage +
        "&start_date=" +
        this.startdate +
        "&end_date=" +
        this.enddate +
        "&order=" +
        ordering +
        "&search=" +
        this.filter;
      if (this.district != null) {
        url = url + "&district=" + this.district;
      }
      const promise = HTTP.get(url);
      return promise
        .then((data) => {
          const items = data.data.results;
          this.total = data.data.count;
          return items || [];
        })
        .catch((e) => {
          console.log(e);
        });
    },
    adminLink: function (id) {
      return (
        process.env.VUE_APP_API_ROOT + "/admin/auth/user/" + id + "/change/"
      );
    },
  },
  watch: {
    startdate: function () {
      this.$refs.table.refresh();
    },
    enddate: function () {
      this.$refs.table.refresh();
    },
    district: function () {
      this.$refs.table.refresh();
    },
  },
};
</script>
